import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BarChart } from "../../BarChart";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ErrorNotify } from "../../Toast";

const loadChart = {
    id: 'loadChart',
    onClick: (chart, args, options) => {
        console.log('chart data is here====================', chart)
        const containerBody = document.querySelector('.containerBody');
        // alert('here')
        if (chart.data.labels.length > 2) {
            containerBody.style.width = '100%';
        }
    }
}

const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: { display: true,position:"bottom" },
      title: {
        display: true,
        position: 'left',
        text: 'Evaluation Score(%)',
    },
      tooltip: {
        callbacks: {
            label: function (tooltipItem) {
                console.log("tooltipItem",tooltipItem)
                let value = tooltipItem.raw || 0; // Get the value
                return `${value}%`; // Append percentage symbol
            }
        }
    },
    },
    scales: {
      x: {
        grid: { display: false }, // X-axis grid hide
      },
      y: {
        grid: { display: false }, // Y-axis grid hide
        ticks: {
          beginAtZero: false,
          min: 0,
          stepSize: 2,
          callback: function (value) {
            return `${value}%`;
          },
        },
      },
    },
    elements: {
        bar: {
          borderRadius: {
            topLeft: 5, // Round top-left corner
            topRight: 5, // Round top-right corner
            bottomLeft: 0, // Keep bottom-left sharp
            bottomRight: 0, // Keep bottom-right sharp
          }
        }
      },
  };
  

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    loadChart
);

const base_url = process.env.REACT_APP_AUTH_BASE_URL;


var chartDatas = [];
var labels = [];
var datasets = [];

const AgentPerformance = (props) => {

    const data = {
        labels,
        datasets: datasets
    };

    // const [agentPerformanceData, setAgentPerformanceData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);

    useEffect(() => {
        if(props?.latestForm?.label!==undefined&&props?.latestForm?.value?.split("||")[0]!==undefined){
        if (typeof props?.formValVersion !== "string" && props?.formValVersion?.form_name !== undefined && props?.formValVersion?.form_version !== undefined) {
            console.log(props?.formValVersion,"props?.formValVersion ")
        
            var url = `${base_url}dashboard/agent-performace`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'start_date': props.fromDateForDash,
                'end_date': props.toDateForDash,
                'daterange': props.dateRange,
                'date_type':props?.dateTypeForDash,
                'form':process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "recruitment" ?[props?.latestForm?.value]:[`${props?.formValVersion?.form_name}||${props?.formValVersion?.form_version}`],
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };
        setIsLoading(true)
        callApi(url, requestOptions, 'agent-performance-data');
    }
}
    }, [props?.dateRange,props?.formValVersion]);

    useEffect(()=>{
        
        chartDatas = [];
        datasets = [];
        labels = [];
        console.log("props?.agentPerformanceData",props?.agentPerformanceData);
        if(props?.agentPerformanceData?.length!==0){
            console.log("HHHHHHHHHHHHHHHHHH");
        props?.agentPerformanceData?.cat?.map((i, j) => {
            labels.push(i.label);
        });
        let chart_type = '';

        props?.agentPerformanceData?.val1&&props?.agentPerformanceData?.val1?.map((i, j) => {
            chartDatas.push(i.value.replace('%', ''));
            chart_type = i.type;
        });

        datasets.push({
            type: chart_type,
            label: 'Evaluation',
            data: chartDatas,
            fill: true,
            backgroundColor: ['rgb(160,214,131)'],
            barPercentage: 0.5,
        });
        chartDatas = [];

        props?.agentPerformanceData?.val2&&props?.agentPerformanceData.val2.map((i, j) => {
            chartDatas.push(i.value.replace('%', ''));
            chart_type = i.type;
        });

        datasets.push({
            type: chart_type,
            label: 'Dispute',
            data: chartDatas,
            fill: true,
            backgroundColor: ['rgb(41,204,180)'],
            barPercentage: 0.5,
        });
        chartDatas = [];
       


        props?.agentPerformanceData?.val3&&props?.agentPerformanceData.val3.map((i, j) => {
            chartDatas.push(i.value.replace('%', ''));
            chart_type = i.type;
        });

        datasets.push({
            type: chart_type,
            label: 'Auto-Fail',
            data: chartDatas,
            fill: true,
            backgroundColor: ['rgb(240,96,66)'],
            barPercentage: 0.5,
        });
        chartDatas = [];

        props?.agentPerformanceData?.val4&&props?.agentPerformanceData.val4.map((i, j) => {
            chartDatas.push(i.value.replace('%', ''));
            chart_type = i.type;
        });

        datasets.push({
            type: chart_type,
            label: 'Coaching',
            data: chartDatas,
            fill: true,
            backgroundColor: ['rgb(240,187,86)'],
            barPercentage: 0.5,
        });
        chartDatas = [];
        console.log('dataset for agent performance is here-------', datasets)
        // props?.setAgentPerformanceData&&props?.setAgentPerformanceData(data.data);
        setErrorMsg(false);
    }
    else{
        console.log("YYYYYYYYYYYYYYYY");
        console.log("RRRRRRRRRR");
        props?.agentPerformanceData?.map((i, j) => {
            labels.push(i.label);
        });
        let chart_type = '';

        props?.agentPerformanceData?.map((i, j) => {
            chartDatas.push(i.value.replace('%', ''));
            chart_type = i.type;
        });

        datasets.push({
            type: chart_type,
            label: 'Evaluation',
            data: chartDatas,
            fill: true,
            backgroundColor: ['rgb(160,214,131)'],
            barPercentage: 0.5,
        });
        chartDatas = [];

        props?.agentPerformanceData?.map((i, j) => {
            chartDatas.push(i.value.replace('%', ''));
            chart_type = i.type;
        });

        datasets.push({
            type: chart_type,
            label: 'Dispute',
            data: chartDatas,
            fill: true,
            backgroundColor: ['rgb(41,204,180)'],
            barPercentage: 0.5,
        });
        chartDatas = [];

       
       
        props?.agentPerformanceData?.map((i, j) => {
            chartDatas.push(i.value.replace('%', ''));
            chart_type = i.type;
        });

        datasets.push({
            type: chart_type,
            label: 'Auto-Fail',
            data: chartDatas,
            fill: true,
            backgroundColor: ['rgb(240,96,66)'],
            barPercentage: 0.5,
        });
        chartDatas = [];

        props?.agentPerformanceData?.map((i, j) => {
            chartDatas.push(i.value.replace('%', ''));
            chart_type = i.type;
        });

        datasets.push({
            type: chart_type,
            label: 'Coaching',
            data: chartDatas,
            fill: true,
            backgroundColor: ['rgb(240,187,86)'],
            barPercentage: 0.5,
        });
        chartDatas = [];
        console.log('dataset for agent performance is here-------', datasets)
        // props?.setAgentPerformanceData&&props?.setAgentPerformanceData(data.data);
        setErrorMsg(false);
    }
    
    },[props?.agentPerformanceData,props?.filterStatus])

    const callApi = async (url, requestOptions, flag) => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'agent-performance-data':
                    console.log('Agent performance data-----------', data.data);
                    if (data.status === 200) {
                        chartDatas = [];
                        datasets = [];
                        labels = [];
                    
                        let chart_type = '';
                    
                        data?.data?.cat?.forEach((i, j) => {
                            if (data?.data?.val1?.[j]?.value !== "0%") {
                                labels.push(i.label);
                            }
                        });
                    
                        chartDatas = data?.data?.val1?.map((i, j) => {
                            if (i.value !== "0%") {
                                chart_type = i.type;
                                return i.value.replace('%', '');
                            }
                            return null;
                        }).filter(Boolean);
                    
                        datasets.push({
                            type: chart_type,
                            label: 'Recruiter',
                            data: chartDatas,
                            fill: true,
                            backgroundColor: ['rgb(160,214,131)'],
                            barPercentage: 0.5,
                        });
                    
                        chartDatas = data?.data?.val2?.map((i, j) => {
                            if (i.value !== "0%") {
                                chart_type = i.type;
                                return i.value.replace('%', '');
                            }
                            return null;
                        }).filter(Boolean);
                    
                        // datasets.push({
                        //     type: chart_type,
                        //     label: 'Dispute',
                        //     data: chartDatas,
                        //     fill: true,
                        //     backgroundColor: ['rgb(41,204,180)'],
                        //     barPercentage: 0.5,
                        // });
                    
                        chartDatas = data?.data?.val3?.map((i, j) => {
                            if (i.value !== "0%") {
                                chart_type = i.type;
                                return i.value.replace('%', '');
                            }
                            return null;
                        }).filter(Boolean);
                    
                        // datasets.push({
                        //     type: chart_type,
                        //     label: 'Auto-Fail',
                        //     data: chartDatas,
                        //     fill: true,
                        //     backgroundColor: ['rgb(240,96,66)'],
                        //     barPercentage: 0.5,
                        // });
                    
                        chartDatas = data?.data?.val4?.map((i, j) => {
                            if (i.value !== "0%") {
                                chart_type = i.type;
                                return i.value.replace('%', '');
                            }
                            return null;
                        }).filter(Boolean);
                    
                        // datasets.push({
                        //     type: chart_type,
                        //     label: 'Coaching',
                        //     data: chartDatas,
                        //     fill: true,
                        //     backgroundColor: ['rgb(240,187,86)'],
                        //     barPercentage: 0.5,
                        // });
                    
                        console.log('dataset for agent performance is here-------', datasets);
                        props?.setAgentPerformanceData && props?.setAgentPerformanceData(data.data);
                        setErrorMsg(false);
                        setIsLoading(false);
                    }
                        else if (data?.status >= 400 || data?.status <= 422) {
                            if(data?.status === 401){
                                localStorage.clear()
                            }
                            setIsLoading(false)
                            setErrorMsg(true);
                            return ErrorNotify(data.message, {
                                icon: "error",
                            });
                            
                        }
                        
                    
                    break;

                default:
                    break;
            }
        }).catch(err=> {
            setIsLoading(false)
            setErrorMsg(true);
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
            icon: "error",
                });
            });
    }

    return (
        <>
            <div id="zoom_chart6" className="chart-card p-4">
                <div className="chart-header d-flex justify-content-between align-items-center">
                    <h4> 
                    {process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] !== "recruitment"
    ? "Agent"
    : "Recruiter"} Performance</h4>
                    <div className="table_filter"></div>
                    <div>
                        <button className="chart_zoomin_btn" onclick="openFullscreen('zoom_chart6');">zoom</button>
                        <button className="chart_zoomout_btn" onclick="closeFullscreen();">zoom</button>
                    </div>
                </div>
                {isLoading && <div id="chartdiv3" className="chart_contents" style={{
                    height: '250px',
                    textAlign: 'center',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    top: '50%'
                }}>
                    <span className={`${isLoading ? 'loading' : ''}`} id="passrateid">
                        <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                    </span>
                </div>}
                {!isLoading &&
                    // <div style={{
                    //     width: '100%',
                    //     overflowX :"scroll"
                    // }}>
                    <div id="custom4_p" className="chart_contents containerBody" style={{ height: '250px' }}>
                        <Bar options={options} data={data} width={window.innerWidth === 1280 ? "600%" : '1000%'} />
                        {errorMsg && <div className="text-center">No data found</div>}
                        {/* <BarChart chartData={agentPerformanceData} /> */}
                    </div>
                    // </div>
                }
            </div>

        </>
    )
}

export default AgentPerformance;