import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BarChart } from "../../BarChart";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ErrorNotify } from "../../Toast";
import { ToastContainer } from "react-toastify";

// const resize = {
//     id: 'resize',
//     afterDraw: (chart, args, options) => {
//         const { chartArea: { width, height, top } } = chart;
//         console.log('resize plugin is here--------')
//     }
// }



ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
  // resize
);

var labels = [];
var labelsData = [];

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const predefinedColors = [
  "#A6DE88",
  "#CDC1FF",
  "#FFB370",
  "#D3EE98",
  "#FFF3BF",
  "#DCE2D4",
  "#E3C5C6",
  "#00FFFF",
  "#FAA0A0",
  "#86CAEA",
];

const getRandomLightColor = (() => {
  let index = 0;
  return () => {
    const color = predefinedColors[index % predefinedColors.length];
    index++;
    return color;
  };
})();

const AQRDistributionChart = ({
  fromDate,
  toDate,
  dateRange,
  filterStatus,
  latestForm,
  formValVersion,
  fromDateForDash,
  toDateForDash,
  dateTypeForDash,
  setIsLoading2,
  evalDetails,
  setEvalDetails,
  aQRData,
  setAQRData,

}) => {
  // const [aQRData, setAQRData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ top: document.body.scrollHeight, behavior: "smooth" });
      // element.scrollIntoView({
      //   behavior: "smooth",
      //   block: "center", // Align to the center of the viewport
      // });
    }
  };
  
  const data = {
    labels,
    datasets: [
      {
        // base: '0',
        barPercentage: 0.5,
        // barThickness: 6,
        label: "",
        xAxisName: "Quality Score(%)-Agents(count)",
        data: labelsData,
        backgroundColor: labels.map(() => getRandomLightColor()),
        type: "bar",
        maxBarThickness: 80,
      },
    ],
  };

     const getEvaluationTableData = (xAxisLabel) => {
        setIsLoading2(true);
        var url = `${base_url}dashboard/evaluations-listing`;
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify({
            type:"range",
            range:xAxisLabel?.replace(/%/g, ""),
            start_date: fromDateForDash,
          end_date: toDateForDash,
          daterange: dateRange,
          date_type: dateTypeForDash,
          form:
            process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "recruitment"
              ? [latestForm?.label||latestForm?.value?.split("||")[0]===undefined?"interview_form||1":latestForm?.value]
              : [
                  `${formValVersion?.form_name}||${formValVersion?.form_version}`,
                ],
            proxy_user_id:
              localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? JSON.parse(localStorage.getItem("settedProxy"))?.value
                : "",
            proxy_by:
              localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? localStorage.getItem("loginUserUniqueid")
                : "",
          }),
        };
    
        fetch(url, requestOptions)
          .then((res) => res.json())
          .then((data) => {
            console.log("eval_Details");
            if (data?.status == 200) {
              setIsLoading2(false);
              setEvalDetails(data?.data);
              
            } else if (data?.status >= 400 && data?.status <= 422) {
              if (data?.status === 401) {
                localStorage.clear();
              }
              return ErrorNotify(data.message, { icon: "error" });
            }
          })
          .catch((err) => {
            setIsLoading2(false);
            return ErrorNotify(
              "Something went wrong.Please contact to Administrator !",
              {
                icon: "error",
              }
            );
          });

         

          handleClickScroll("evalDatas")
          
      };

     

  const handleBarClick = (event, elements, chart) => {
    if (elements.length > 0) {
      const elementIndex = elements[0].index; // Bar index
      const datasetIndex = elements[0].datasetIndex; // Dataset index
      const clickedData = chart.data.datasets[datasetIndex].data[elementIndex];
      const xAxisLabel = chart.data.labels[elementIndex]; // X-axis label
  
      console.log("Clicked bar data:", clickedData);
      console.log("X-axis label:", xAxisLabel);

  
      // Custom function call
      getEvaluationTableData(xAxisLabel);
    }
  };



  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      title: {
        display: true,
        position: "left",
        text:
          process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] !== "recruitment"
            ? "Agent"
            : "Evaluation Count",
      },
      // resize: [resize]
    },
    scales: {
      x: { grid: { display: false } },
      y: { grid: { display: false }, ticks: { beginAtZero: true } },
    },
    elements: {
      bar: {
        borderRadius: {
          topLeft: 5, // Round top-left corner
          topRight: 5, // Round top-right corner
          bottomLeft: 0, // Keep bottom-left sharp
          bottomRight: 0, // Keep bottom-right sharp
        }
      }
    },
    onClick: handleBarClick,
    
  };
  useEffect(() => {
    if(latestForm?.label!==undefined&&latestForm?.value?.split("||")[0]!==undefined){
    if (
      typeof formValVersion !== "string" &&
      formValVersion?.form_name &&
      formValVersion?.form_version
    ) {
      var url = `${base_url}dashboard/agent-range`;
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          start_date: fromDateForDash,
          end_date: toDateForDash,
          daterange: dateRange,
          date_type: dateTypeForDash,
          form:
            process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "recruitment"
              ? [latestForm?.value]
              : [
                  `${formValVersion?.form_name}||${formValVersion?.form_version}`,
                ],
          proxy_user_id:
            localStorage.getItem("settedProxy") !== null &&
            localStorage.getItem("settedProxy") !== undefined
              ? JSON.parse(localStorage.getItem("settedProxy"))?.value
              : "",
          proxy_by:
            localStorage.getItem("settedProxy") !== null &&
            localStorage.getItem("settedProxy") !== undefined
              ? localStorage.getItem("loginUserUniqueid")
              : "",
        }),
      };
      setIsLoading(true)
      callApi(url, requestOptions, "aqr-data");
    }
  }
  }, [dateRange, formValVersion]);

  useEffect(() => {
    setErrorMsg(false);
    labelsData = [];
    labels = [];
    aQRData.map((i, j) => {
      labels.push(i.label);
      labelsData.push(i.value);
    });
  }, [aQRData, filterStatus]);

  const callApi = async (url, requestOptions, flag) => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "aqr-data":
            console.log("aqr data-----------", data.data);
            if (data.status === 200) {
              setAQRData && setAQRData(data.data);
              setErrorMsg(false);
              setIsLoading(false);
              labelsData = [];
              labels = [];

              data.data.map((i, j) => {
                labels.push(i.label);
                labelsData.push(i.value);
              });
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status === 401) {
                localStorage.clear();
              }
              setIsLoading(false);
              setErrorMsg(true);
              return ErrorNotify(data.message, {
                icon: "error",
              });
            }
            break;

          default:
            break;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        return ErrorNotify(
          "Something went wrong.Please contact to Administrator !",
          {
            icon: "error",
          }
        );
      });
  };

  return (
    <>
      {isLoading && (
        <div id="zoom_chart4" className="chart-card p-4">
          <ToastContainer />
          <div className="chart-header d-flex justify-content-between align-items-center">
            <h4>
              {process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] !==
              "recruitment"
                ? "Agent"
                : "Evaluation Count"}{" "}
              / Quality Range Distribution
            </h4>
            <div className="table_filter"></div>
            <div>
              <button
                className="chart_zoomin_btn"
                onclick="openFullscreen('zoom_chart4');"
              >
                zoom
              </button>
              <button
                className="chart_zoomout_btn"
                onclick="closeFullscreen();"
              >
                zoom
              </button>
            </div>
          </div>
          <div
            id="overallagent_chart"
            className="chart_contents"
            style={{
              height: "250px",
              textAlign: "center",
              position: "absolute",
              left: "0",
              right: "0",
              bottom: "0",
              top: "50%",
            }}
          >
            <span className={`${isLoading ? "loading" : ""}`} id="passrateid">
              <span class="loader">
                <span
                  class="spinner-border"
                  role="status"
                  aria-hidden="true"
                ></span>
              </span>
            </span>
          </div>
        </div>
      )}

      {!isLoading && (
        <div id="zoom_chart4" className="chart-card p-4">
          <div className="chart-header d-flex justify-content-between align-items-center">
            <h4>
              {process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] !==
              "recruitment"
                ? "Agent"
                : "Evaluation Count"}{" "}
              / Quality Range Distribution
            </h4>
            <div className="table_filter"></div>
            <div>
              <button
                className="chart_zoomin_btn"
                onclick="openFullscreen('zoom_chart4');"
              >
                zoom
              </button>
              <button
                className="chart_zoomout_btn"
                onclick="closeFullscreen();"
              >
                zoom
              </button>
            </div>
          </div>
          <div
            id="overallagent_chart"
            className=""
            style={{
              height: "280px",
              position: "relative",
              paddingBottom: "20px",
            }}
          >
            <Bar
              options={options}
              data={data}
              width={window.innerWidth === 1280 ? "600%" : "1000%"}
            />
            <div
              className="text-center"
              style={{
                marginTop: "20px",
                position: "absolute",
                bottom: "0px",
                left: "50%",
                fontFamily:"Source Sans Pro",
                fontSize:15,
                color:"rgb(153,153,153)",
                fontStyle:"normal",
                transform: "translateX(-50%)",
                fontWeight: "bold",
              }}
            >
              Quality Score (%)
            </div>
            {errorMsg && <div className="text-center">No data found</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default AQRDistributionChart;
