import React, { useEffect, useState } from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import AutoComplete from "./AutoComplete";
import Select from 'react-select';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import moment from 'moment';
import swal from 'sweetalert';
import Timer from "../../shared/Timer";
import { DismissToast, ErrorNotify, SuccessNotify } from "../../shared/Toast";
import { useLocation, useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useRef } from "react";


const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const base_name = process.env.PUBLIC_URL;
var agentSupervisorName = '';
var agent_id_val = '';
var client_type = '';
var timeStr = '00:00:00';

var url_call_id;
var url_lob;
var url_campaign;
var url_vender;
var url_location;
var all_data_string;
var data_by_call_id;


const AddEvaluation = () => {
    TabTitle('Add-Evaluation :: NEQQO');



    const navigate = useNavigate();
    const search = useLocation().search;
    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');
    const [showDropdown, setShowDropdown] = useState(false);

    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);

    const [formData, setFormData] = useState([]);
    const [agentData, setAgentData] = useState([]);

    const [defaultValueC1, setdefaultValueC1] = useState('');
    const [defaultValueC2, setdefaultValueC2] = useState('');
    const [defaultValueC3, setdefaultValueC3] = useState('');
    const [defaultValueC4, setdefaultValueC4] = useState('');

    const [isC1Data, setIsC1Data] = useState(false);
    const [isSupDisabled, setIsSupDisabled] = useState(true);

    const [showEvaluateBtn, setShowEvaluateBtn] = useState(true);
    const [allClasses, setAllClasses] = useState('col-sm-6 col-md-4 col-lg-3 form-group d-none')

    const [agentSupervisor, setAgentSupervisors] = useState('');
    const [otherEvaluatorsData, setOtherEvaluatorsData] = useState([]);
    const [callDateTime, setCallDateTime] = useState('');
    // const [callDateTime, setCallDateTime] = useState(moment(callDateTime).format('MM-DD-YYYY, h:mm:ss A'));

    const [autoGeneratedId, setAutoGeneratedId] = useState('');
    const [agents, setAgents] = useState([])
    const [nameOfAgent, setNameOfAgent] = useState('');
    const [input, setInput] = useState("")

    const [callDuration, setCallDuration] = useState('');
    const [affiliationValue, setAffiliationValue] = useState('');
    const [evaluationStatus, setEvaluationStatus] = useState('Pending');
    const [evaluatorName, setEvaluatorName] = useState('');

    const [hierarchyData, setHierarchyData] = useState([]);

    const [assignValue, setAssignValue] = useState('');
    const [mediaValue, setMediaValue] = useState('');
    const [file, setFile] = useState({});
    const [fileName, setFileName] = useState(false);
    const fileInputRef = useRef(null);
    const [resetBool, setResetBool] = useState(false)

    const [isLoadingEvaluate, setIsLoadingEvaluate] = useState(false)
    const [isDataFound, setIsDataFound] = useState(false)
    const [formName, setFormName] = useState('');

    const [refresh, setRefresh] = useState(false);
    const [callChatIdDisabled, setCallChatIdDisabled] = useState(true);
    const [channelType, setChannelType] = useState("");

    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);

    window.addEventListener('DOMContentLoaded', (event) => {
        const uploadInput = document.getElementById('upload');
        if (uploadInput) {
            uploadInput.setAttribute('input', handleFileSelection);
        }
    });


    function handleFileSelection(files) {
        const filteredFiles = Array.from(files).filter(file => file.type === 'audio/wav' || file.name.toLowerCase().endsWith('.wav'));

        if (filteredFiles.length > 0) {
            // Process the filtered files or perform any necessary operations
            console.log(filteredFiles);
        }
    }

    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }

    // const setHoursValue = (val) => {
    //     var hoursVal = 24;
    //     if (val > hoursVal) {
    //         setHours(hoursVal);
    //     } else {
    //         setHours(val);
    //     }
    // }

    // const setMinutesValue = (val) => {
    //     var minValue = 59;
    //     if (val > minValue) {
    //         setMinutes(minValue);
    //     } else {
    //         setMinutes(val);
    //     }
    // }

    // const setSecondValue = (val) => {
    //     var secValue = 59;
    //     if (val > secValue) {
    //         setSeconds(secValue);
    //     } else {
    //         setSeconds(val);
    //     }
    // }

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const setHoursValue = (val) => {
        var hoursVal = 24;
        const parsedVal = parseInt(val, 10); // Parse the input value as an integer

        if (parsedVal > hoursVal) {
            setHours(hoursVal.toString());
        } else {
            setHours(parsedVal.toString());
        }
    };

    const setMinutesValue = (val) => {
        var minValue = 59;
        const parsedVal = parseInt(val, 10); // Parse the input value as an integer

        if (parsedVal > minValue) {
            setMinutes(minValue.toString());
        } else {
            setMinutes(parsedVal.toString());
        }
    };

    const setSecondValue = (val) => {
        var secValue = 59;
        const parsedVal = parseInt(val, 10); // Parse the input value as an integer

        if (parsedVal > secValue) {
            setSeconds(secValue.toString());
        } else {
            setSeconds(parsedVal.toString());
        }
    };

    // timeStr = hours;
    // timeStr = timeStr + ':' + minutes;
    // timeStr = timeStr + ':' + seconds;
    // console.log("TimeStr ========", timeStr)


    const options = [];

    // Generate options from 1 to 99
    for (let i = 1; i <= 99; i++) {
        options.push({ value: i, label: i.toString() });
    }

    const handleFutureDate = (date) => {
        const inputElement = document.getElementById('callDateTimeInput');

        inputElement.addEventListener('change', function () {
            const selectedDate = new Date(this.value);
            const currentDate = new Date();
            const currentTime = new Date().setHours(0, 0, 0, 0);
            if (selectedDate.getTime() === currentTime) {
                this.value = ''; // Clear the input value
                ErrorNotify('Please select a valid time.');
            }

            if (selectedDate > currentDate) {
                this.value = ''; // Clear the input value
                ErrorNotify('Please select a date that is not in the future.');
                return;
            }
        });
    }

    useEffect(() => {

        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (isNaN(data.status)) {
                Logout();
                return
            }
            var arr = [];
            data.data.map((i, j) => {
                arr[i.custom1_id] = i.custom1_name;
                arr[i.custom2_id] = i.custom2_name;
                arr[i.custom3_id] = i.custom3_name;
                arr[i.custom4_id] = i.custom4_name;
            })
            // arr['c2'] = 'Vendor';
            // arr['c3'] = 'LOB';
            // arr['c1'] = 'Campaign';
            // arr['c4'] = 'Location';
            console.log('arr -===========', arr)
            setHierarchyData(arr);

        }).catch(err => {
            console.log(err);
        });

        var url = `${base_url}custom/1`;
        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('lob data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }

            setIsC1Data(true);
            setC1Data(data.data);
            setIsSupDisabled(false);
        }).catch(err => {
            console.log(err);
        });

        if (localStorage.getItem('userRole') === 'Quality Supervisor') {
            url_call_id = new URLSearchParams(search).get("call_id");
            url_lob = new URLSearchParams(search).get("lob");
            url_campaign = new URLSearchParams(search).get("campaign");
            url_vender = new URLSearchParams(search).get("vendor");
            url_location = new URLSearchParams(search).get("location");
            all_data_string = JSON.parse(new URLSearchParams(search).get("allDataString"))
            data_by_call_id = all_data_string?.filter(i => i?.call_id === url_call_id)
            setCallDateTime(data_by_call_id && moment(data_by_call_id[0]?.datetime).format("YYYY-MM-DDTHH:mm:ss"))
            setHours(data_by_call_id && data_by_call_id[0]?.duration?.split(":")[0])
            setMinutes(data_by_call_id && data_by_call_id[0]?.duration?.split(":")[1])
            setSeconds(data_by_call_id && data_by_call_id[0]?.duration?.split(":")[2])
            setNameOfAgent(data_by_call_id && data_by_call_id[0]?.call_meta_data?.agentname)
            agentSupervisorName = data_by_call_id && data_by_call_id[0]?.call_meta_data?.agentname
            setAgentSupervisors(data_by_call_id ? data_by_call_id[0]?.call_meta_data?.agentname : "")
            // setdefaultValueC1(url_lob);
            // setdefaultValueC2(url_campaign);
            // setdefaultValueC3(url_vender);
            // setdefaultValueC4(url_location);
            console.log("data_by_call_id", data_by_call_id);

            if (url_call_id !== null) {

                var url = `${base_url}calls/get-form-name`;
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    },
                    body: JSON.stringify({
                        c1: url_lob,
                        c2: url_campaign,
                        c3: url_vender,
                        c4: url_location,
                        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                    
                    })
                };

                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('form data======', data);


                    if (data?.status == 200 || data?.status == 201) {
                        // SuccessNotify(data.message, {
                        //     icon: "success",
                        // })

                        setFormData(data.data)
                        setdefaultValueC1(url_lob);
                        setdefaultValueC2(url_campaign);
                        setdefaultValueC3(url_vender);
                        setdefaultValueC4(url_location);

                    }

                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        return;
                    }
                }).catch(err => {
                    DismissToast()
                    ErrorNotify("Something went wrong, Please contact to Administrator !", {
                        icon: "error",
                    });
                    return;
                    // console.log('========================', data.data);
                });
            }
        }

    }, []);


    // const handleTime = () => {
    //     const hoursInputElement = document.getElementById('hoursInput');
    //     const minutesInputElement = document.getElementById('minutesInput');
    //     const secondsInputElement = document.getElementById('secondsInput');
    //     const timeInputElement = document.getElementById('timeElementInput');

    //     timeInputElement.addEventListener('input', function () {
    //         const inputValue = this.value.trim();
    //         // Remove non-numeric characters
    //         const numericValue = inputValue.replace(/[^0-9]/g, '');
    //         // Restrict the input to a maximum of 2 numbers
    //         const maxValue = numericValue.slice(0, 2);
    //         // Update the input value
    //         this.value = maxValue;
    //     });
    //     hoursInputElement.addEventListener('input', function () {
    //         const inputValue = this.value.trim();
    //         // Remove non-numeric characters
    //         const numericValue = inputValue.replace(/[^0-9]/g, '');
    //         // Restrict the input to a maximum of 2 numbers
    //         const maxValue = numericValue.slice(0, 2);
    //         // Update the input value
    //         this.value = maxValue;
    //     });
    //     minutesInputElement.addEventListener('input', function () {
    //         const inputValue = this.value.trim();
    //         // Remove non-numeric characters
    //         const numericValue = inputValue.replace(/[^0-9]/g, '');
    //         // Restrict the input to a maximum of 2 numbers
    //         const maxValue = numericValue.slice(0, 2);
    //         // Update the input value
    //         this.value = maxValue;
    //     });
    //     secondsInputElement.addEventListener('input', function () {
    //         const inputValue = this.value.trim();
    //         // Remove non-numeric characters
    //         const numericValue = inputValue.replace(/[^0-9]/g, '');
    //         // Restrict the input to a maximum of 2 numbers
    //         const maxValue = numericValue.slice(0, 2);
    //         // Update the input value
    //         this.value = maxValue;
    //     });


    // }

    const setAutoGeneratedIdFunc = () => {
        console.log("LLLLLLLLLLLLLLLLLLLL");
        setCallChatIdDisabled(false);

        if (nameOfAgent === '') {
            ErrorNotify('Please enter agent name');
            return
        }
        if (agent_id_val === '') {
            ErrorNotify('Please enter agent name');
            return
        }
        if (callDateTime.length !== "YYYY-MM-DDTHH:mm:ss".length) {
            ErrorNotify('Please enter Call Date and Time');
            return
        }
        console.log("agentSupervisorName",agentSupervisorName + '' + moment(callDateTime).format('MMDDYYYYHHmmss'));
        setAutoGeneratedId(agentSupervisorName + '' + moment(callDateTime).format('MMDDYYYYHHmmss'));

    }

    const hideEvaluateBtn = (e) => {

        console.log("erererere", e)
        setAssignValue(e);

        if (e === 'none' || e === 'other') {
            setShowEvaluateBtn(false);
            setAllClasses('col-sm-6 col-md-4 col-lg-3 form-group d-none');
        } else {
            setShowEvaluateBtn(true);
            setAllClasses('col-sm-6 col-md-4 col-lg-3 form-group d-none');
        }
        if (e === 'other') {
            setAllClasses('col-sm-6 col-md-4 col-lg-3 form-group');
            getOtherEvaluators();
        }

        if (e === 'none') {
            setEvaluationStatus('Unassigned');
            setEvaluatorName('');
        } else if (e === 'myself' || e === 'other') {
            setEvaluationStatus('Pending');
        }
        if (e === 'myself') {
            setEvaluatorName(localStorage.getItem('loginUserUniqueid'));
        }

    }

    const getOtherEvaluators = () => {
        var url = `${base_url}calls/get-other-evaluators`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                userId: localStorage.getItem('loginUserId'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                    
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('setOtherEvaluatorsData data======', data);




            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                setOtherEvaluatorsData(data.data);
            }

            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            return;
            // console.log('========================', data.data);
        });
    }
    const selectRef = useRef(null);

    const handleC2Change = (e) => {
        // document.getElementById('reset').value=""
        // document.getElementById('media').value=""
        // document.getElementById('upload').value=null
        //console.log("e.target.value",e);
        // document.getElementById('optionC2Id').value = { label: "Select", value: "" }
        setRefresh(!refresh);
        if (e.value === "Select") {

            setC2Data([]);
            setC3Data([]);
            setC4Data([]);

            setdefaultValueC1("");
            setdefaultValueC2("");
            setdefaultValueC3("");
            setdefaultValueC4("");
            setFormName('')
            return;
        }
        setNameOfAgent("")
        setInput("")
        setMediaValue("")
        setFile({})
        agentSupervisorName = '';
        setAgentSupervisors('')
        setCallDateTime('');
        setAutoGeneratedId('');

        setdefaultValueC2("");
        setdefaultValueC3("");
        setdefaultValueC4("");
        setHours('00');
        setMinutes('00');
        setSeconds('00');
        timeStr = '00:00:00';
        setFormName('')
        setAssignValue('')
        setEvaluatorName('')

        setC2Data([]);
        setC3Data([]);
        setC4Data([]);
        setFormData([]);

        var url = `${base_url}custom/2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: e.value,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c2 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            setdefaultValueC1(e.value);
            //  console.log("setdefaultValueC1eeeeeeeee",setdefaultValueC1)
            setC2Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC3Change = (e) => {
        // document.getElementById('reset').value=""
        // document.getElementById('media').value=""
        // document.getElementById('upload').value=null
        setRefresh(!refresh);
        if (e.value === "Select") {
            setC3Data([]);
            setC4Data([]);
            setdefaultValueC2("");
            setdefaultValueC3("");
            setdefaultValueC4("");
            setFormName('')
            return;
        }
        setInput("")
        setNameOfAgent("")
        setMediaValue("")
        setFile('')
        agentSupervisorName = '';
        setAgentSupervisors('')
        setCallDateTime('');
        setAutoGeneratedId('');
        setHours('00');
        setMinutes('00');
        setSeconds('00');
        timeStr = '00:00:00';
        setdefaultValueC3("");
        setdefaultValueC4("");
        setFormName('')
        setAssignValue('')
        setEvaluatorName('')

        setC3Data([]);
        setC4Data([]);
        setFormData([]);

        if (e !== '') {
            var url = `${base_url}custom/3`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
                body: JSON.stringify({
                    custom1: defaultValueC1,
                    custom2: e.value
                })
            };

            fetch(url, requestOptions).then(res => res.json()).then(data => {
                // console.log('c3 data======', data);
                if (data.status != 200) {
                    ErrorNotify(data.message);
                    return;
                }
                setdefaultValueC2(e.value);
                setC3Data(data.data);
            }).catch(err => {
                console.log(err);
            });

        }
    }

    const handleC4Change = (e) => {
        // document.getElementById('reset').value=""
        // document.getElementById('media').value=""
        // document.getElementById('upload').value=null
        if (e.value === "Select") {
            setC4Data([]);
            setdefaultValueC3("");
            setdefaultValueC4("");
            setFormName('')
            return;
        }
        setNameOfAgent("")
        setMediaValue("")
        setInput("")
        setFile('')
        agentSupervisorName = '';
        setAgentSupervisors('')
        setCallDateTime('');
        setAutoGeneratedId('');
        setHours('00');
        setMinutes('00');
        setSeconds('00');
        timeStr = '00:00:00';
        setdefaultValueC4("");
        setFormName('')
        setAssignValue('')
        setEvaluatorName('')


        setC4Data([]);
        setFormData([]);

        if (e !== '') {
            var url = `${base_url}custom/4`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
                body: JSON.stringify({
                    custom1: defaultValueC1,
                    custom2: defaultValueC2,
                    custom3: e.value,
                })
            };

            fetch(url, requestOptions).then(res => res.json()).then(data => {
                // console.log('c3 data======', data);
                if (data.status != 200) {
                    ErrorNotify(data.message);
                    return;
                }
                setdefaultValueC3(e.value);
                setC4Data(data.data);
            }).catch(err => {
                console.log(err);
            });
        }
    }

    const handleC5Change = (e) => {
        // document.getElementById('reset').value=""
        // document.getElementById('media').value=""
        // document.getElementById('upload').value=null
        setInput("")
        setMediaValue("")
        setNameOfAgent("")
        setFile('')
        agentSupervisorName = '';
        setAgentSupervisors('')
        setCallDateTime('');
        setAutoGeneratedId('');
        setHours('00');
        setMinutes('00');
        setSeconds('00');
        timeStr = '00:00:00';
        setFormName('')
        setAssignValue('')
        setEvaluatorName('')

        setFormData([]);

        if (e !== '') {
            var url = `${base_url}calls/get-form-name`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
                body: JSON.stringify({
                    c1: defaultValueC1,
                    c2: defaultValueC2,
                    c3: defaultValueC3,
                    c4: e.value,
                    proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                    
                })
            };

            fetch(url, requestOptions).then(res => res.json()).then(data => {
                console.log('form data======', data);

                if (data?.status == 200 || data?.status == 201) {
                    // SuccessNotify(data.message, {
                    //     icon: "success",
                    // })

                    setFormData(data.data)
                    setdefaultValueC4(e.value);
                }

                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    return;
                }
            }).catch(err => {
                DismissToast()
                ErrorNotify("Something went wrong, Please contact to Administrator !", {
                    icon: "error",
                    toastId: "delete-user"
                });
                return;
                // console.log('========================', data.data);
            });
        }
    }

    const getAgentSupervisors = (val, inputVal) => {
        agent_id_val = val;
        var url = `${base_url}calls/get-agents-super-visor`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                agent_id: val,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                    
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            agentSupervisorName = val;

            if (data?.status == 200 || data?.status == 201) {
                // SuccessNotify(data.message, {
                //     icon: "success",
                // })
                setAgentSupervisors(data.data.super_visior_name + ' (' + data.data.super_visior_id + ')');
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
                toastId: "delete-user"
            });
            return;
            // console.log('========================', data.data);
        });
        // console.log('-------------------------------',agentSupervisorName);
    }

    // const handleMediaValue = (e) => {
    //     setMediaValue(e);

    //     if(e === 'no'){
    //         setFile('')
    //         setFileName(false)
    //         console.log("mediaValuev1no",file)
    //     }
    //     if(e === 'yes'){
    //         setFileName(true)
    //         console.log("mediaValuev1yes",file)
    //     }
    //     console.log("mediaValuev2over",file)
    // }
    const handleMediaValue = (selectedOption) => {
        setMediaValue(selectedOption.value);

        if (selectedOption.value === 'no') {
            setFile('');
            setFileName(false);
            console.log("mediaValuev1no", file)
        }
        if (selectedOption.value === 'yes') {
            setFileName(true);
            if (fileInputRef.current) {
                // Reset the file input by creating a new one
                const newFileInput = document.createElement('input');
                newFileInput.type = 'file';
                newFileInput.accept = 'audio/wav';
                newFileInput.name = 'shadow';
                newFileInput.required = true;
                newFileInput.addEventListener('change', (e) => {
                    setFile(e.target.files[0]);
                    setFileName(true);
                });

                // Replace the old file input with the new one
                fileInputRef.current.parentNode.replaceChild(newFileInput, fileInputRef.current);

                // Update the file input reference to the new input
                fileInputRef.current = newFileInput;
                console.log("mediaValuev1yes", file)
            }
        }
        console.log("mediaValuev2over", file)
    }

    // const fileUpload = (e) => {
    //     console.log("mediaValuevfileuploaded1")
    //     setFile(e.target.files[0]);
    //     console.log("mediaValuevfileuploadeddone")
    //     if (!fileName){
    //         e.target.value = null
    //         console.log("mediaValuevfileuploadDeletion")
    //     }


    // }
    const getAgents = (e) => {
        console.log("KKKKKKKKKKKKK");
        var url = `${base_url}calls/get-agents`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                name: e,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                    
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('agent names are======', data);
            // if (data.status != 200) {
            //     ErrorNotify(data.message);
            //     return;
            // }
            // setdefaultValueC3(e);
            // setC4Data(data.data);
        }).catch(err => {
            console.log(err);
        });

    }

    const resetForm = () => {
        // alert('ere')
        setdefaultValueC1('');
        setdefaultValueC2('');
        setdefaultValueC3('');
        setdefaultValueC4('');
        setC1Data([]);
        setC2Data([]);
        setC3Data([]);
        setC4Data([]);
        setFormData([]);
        setAgentData([]);
        setAgentSupervisors('');
        setCallDateTime('');
        setAutoGeneratedId('');
        setCallDuration('');
        setEvaluatorName('');
        setAffiliationValue('');
        setAffiliationValue('');
    }

    const handleFileChange = (file) => {

    }
    const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });
    console.log("DUdtssaasd", currentTime)

    const handleAddEvaluation = (isEva = 0) => {

        console.log("agentDataagentData", hours, minutes, seconds);

        const data = new FormData();
        // if(hours===undefined || minutes===undefined || seconds===undefined){
        //     ErrorNotify("Please enter call duration");
        //     return;
        // }
        // if(hours===undefined && minutes===undefined && seconds==="00"){
        //     ErrorNotify("Please enter call duration")
        //     return;
        // }
        // if(hours==="00" && minutes==="00" && seconds==="00"){
        //     ErrorNotify("Call duration can not be 00:00:00")
        //     return;
        // }
        // if(hours?.length<2 || minutes?.length<2 || seconds?.length<2){
        //     ErrorNotify("Call duration should be at least 2 digits")
        //     return;
        // }
        // if(hours?.length<2 && minutes?.length<2 && seconds?.length<2){
        //     ErrorNotify("Call duration should be at least 2 digits")
        //     return;
        // }

        // timeStr =  hours.length === 1 ? '0' + hours : hours;
        // timeStr = timeStr + ':' + minutes;
        // timeStr = timeStr + ':' + seconds;
        if (defaultValueC1 === '' || defaultValueC1?.length === 0 || defaultValueC1 === 'Select') {
            ErrorNotify(`Please select ${hierarchyData.c1} value`);
            return;
        }
        if (defaultValueC2 === '' || defaultValueC2?.length === 0 || defaultValueC2 === 'Select') {
            ErrorNotify(`Please select ${hierarchyData.c2} value`);
            return;
        }
        if (defaultValueC3 === '' || defaultValueC3?.length === 0 || defaultValueC3 === 'Select') {
            ErrorNotify(`Please select ${hierarchyData.c3} value`);
            return;
        }
        if (defaultValueC4 === '' || defaultValueC4?.length === 0 || defaultValueC4 === 'Select') {
            ErrorNotify(`Please select ${hierarchyData.c4} value`);
            return;
        }

        timeStr = (hours.length === 1 ? '0' + hours : hours) +
            ':' +
            (minutes.length === 1 ? '0' + minutes : minutes) +
            ':' +
            (seconds.length === 1 ? '0' + seconds : seconds);

        console.log("TimeStr ========", timeStr)



        if (formName === '') {
            ErrorNotify('Please select Form Name');
            return;
        }
        if (nameOfAgent === '') {
            ErrorNotify('Please select agent name');
            return;
        }
        if (agentSupervisor === '') {
            ErrorNotify('Please enter supervisor name');
            return;
        }
        if (callDateTime === '') {
            ErrorNotify('Please select call date time');
            return;
        }
        if (callDateTime.length !== "YYYY-MM-DDTHH:mm:ss".length) {
            ErrorNotify('Please enter Call Date and Time');
            return
        }
        if (url_call_id === null) {
            if (autoGeneratedId === '') {
                ErrorNotify('Please select Call/Chat ID');
                return;
            }

        }

        if (evaluationStatus === '') {
            ErrorNotify('Please select Evaluation status');
            return;
        }
        if (timeStr === 'HH:MM:SS') {
            ErrorNotify('Please select Call Duration');
            return;
        }
        if (timeStr === '00:00:00') {
            ErrorNotify('Please select Call Duration');
            return;
        }
        if (timeStr === 'undefined:undefined:undefined') {
            ErrorNotify('Please select Call Duration');
            return;
        }
        if (hours === 'HH' && minutes === 'MM' && seconds === 'SS') {
            ErrorNotify("Call duration can not be HH:MM:SS")
            return;
        }
        if (hours === 'NaN' && minutes === 'NaN' && seconds === 'NaN') {
            ErrorNotify("Please Select Call duration ")
            return;
        }
        if (hours === '00' && minutes === '00' && seconds === '00') {
            ErrorNotify("Call duration can not be 00:00:00")
            return;
        }
        if (hours === undefined && minutes === undefined && seconds === "00") {
            ErrorNotify("Please enter call duration undefineed with 00")
            return;
        }
        if (hours === undefined || minutes === undefined || seconds === undefined) {
            ErrorNotify("Please enter call duration undefine")
            return;
        }
        if (hours === '' && minutes === '' && seconds === '') {
            ErrorNotify("Please enter call duration its should not be empty  ")
            return;
        }

        // if (hours?.length < 2 || minutes?.length < 2 || seconds?.length < 2) {
        //     ErrorNotify("Call duration should be at least 2 digits")
        //     return;
        // }
        // if (hours?.length < 2 && minutes?.length < 2 && seconds?.length < 2) {
        //     ErrorNotify("Call duration should be at least 2 digits")
        //     return;
        // }
        if (assignValue === '') {
            ErrorNotify('Please select Assign to ');
            return;
        }
        // if (mediaValue === '') {
        //     ErrorNotify('Please select mediaValue ');
        //     return;
        // }

        // console.log(file.length)
        //         if (Object.keys(file).length) {
        //             console.log('file name======', file)
        //         }

        // console.log('file--------', file.name.split('.').pop())
        // return
        // let fileType = file.name !== undefined ? file.name.split('.').pop() : '';
        // if (fileType !== 'mp3' && fileType !== 'wav') {
        //     ErrorNotify(`'${fileType}' file type is not supported! Please select only .mp3 or mpeg file types.`);
        //     return;
        //  }
        console.log("channelType",channelType);
        
        data.append('file', file);
        data.append('agent', agent_id_val ? agent_id_val : nameOfAgent);
        data.append('c1', defaultValueC1);
        data.append('c2', defaultValueC2);
        data.append('c3', defaultValueC3);
        data.append('c4', defaultValueC4);
        data.append('userId', localStorage.getItem('loginUserId'));
        data.append('call_id', url_call_id !== null ? url_call_id : autoGeneratedId);
        data.append('call_date', callDateTime);
        data.append('call_duration', timeStr);
        console.log("formnameformname", formName)
        data.append('form_name', String(formName).split('||')[0]);
        data.append('form_version', String(formName).split('||')[1]);
        data.append('affiliation', 'mattsenkumar');
        data.append('evaluation_status', evaluationStatus);
        data.append('evaluator', evaluatorName);
        // data.append('media', mediaValue);
        data.append('channel',channelType)
        data.append("proxy_user_id", localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "");
        data.append("proxy_by", localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : "");


        // data.append('data', JSON.stringify({
        //     agent: agent_id_val,
        //     c1: defaultValueC1,
        //     c2: defaultValueC2,
        //     c3: defaultValueC3,
        //     c4: defaultValueC4,
        //     userId: localStorage.getItem('loginUserId'),
        //     call_id: autoGeneratedId,
        //     call_date: callDateTime,
        //     call_duration: timeStr,
        //     form_name: formData[0].form_name,
        //     form_version: formData[0].form_version,
        //     channel: 'channel',
        //     affiliation: affiliationValue,
        //     evaluation_status: evaluationStatus,
        //     evaluator: evaluatorName,
        //     media: mediaValue
        // }))

        var url = `${base_url}calls/add`;

        const requestOptions = {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },

            body: data
            // body: JSON.stringify({
            //     agent: agent_id_val,
            //     c1: defaultValueC1,
            //     c2: defaultValueC2,
            //     c3: defaultValueC3,
            //     c4: defaultValueC4,
            //     userId: localStorage.getItem('loginUserId'),
            //     call_id: autoGeneratedId,
            //     call_date: callDateTime,
            //     call_duration: timeStr,
            //     form_name: formData[0].form_name,
            //     form_version: formData[0].form_version,
            //     channel: 'channel',
            //     affiliation: 'mattsenkumar',
            //     evaluation_status: evaluationStatus,
            //     evaluator: evaluatorName,
            //     media: mediaValue
            // })
        };
        // console.log('evaluation data is here=========', requestOptions)
        // return
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('evaluation data is======', data);
            if (data?.status == 200 || data?.status == 201) {
                SuccessNotify(data.message, {
                    icon: "success",
                })
                if (isEva) {
                    setIsDataFound(false);
                    window.location.href = `${base_name}/new-form-preview?formName=${data.data.form_name}&formVersion=${data.data.form_version}&moduleName=evaluation&authSecondId&resolvedSecId&call_id=${data.data.call.call_id}`
                } else {
                    setIsDataFound(true);
                    SuccessNotify(data.message);
                    setTimeout(() => {
                        setIsDataFound(false);
                        window.location.reload(false);
                        return;
                    }, 2000);
                }
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsDataFound(false);
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    setIsDataFound(false);
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                setIsDataFound(false);
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsDataFound(false);
            return;
            // console.log('========================', data.data);
        });
    }

    const handleReset = () => {
        setResetBool(true);
        setTimeout(() => {
            setResetBool(false);
            window.location.reload(false);
            return;
        }, 800);


    }

    const renderDropDown = (val) => {
        console.log('render dropdown value========', val)
        if (val === 'LOB') {
            return (
                <>
                    <select onChange={(e) => handleC2Change(e.target.value)} required={true} className="form-control  hide_manager">
                        <option value='' disabled>Select</option>
                        {c1Data.map((i, j) => {
                            return (
                                <option value={i}>{i}</option>
                            )
                        })}
                    </select>
                </>
            )
        }

        if (val === 'Campaign') {
            return (
                <>
                    <select className="form-control hide_manager" onChange={(e) => handleC3Change(e.target.value)}>
                        <option value="" >Select</option>
                        {c2Data.map((i, j) => {
                            return (
                                <option value={i}>{i}</option>
                            )
                        })}
                    </select>
                </>
            )
        }

        if (val === 'Vendor') {
            return (
                <>
                    <select onChange={(e) => handleC4Change(e.target.value)} className="form-control" required="">
                        <option value="" defaultValue="" >Select</option>
                        {c3Data.map((i, j) => {
                            return (
                                <option value={i}>{i}</option>
                            )
                        })}
                    </select>
                </>
            )
        }

        if (val === 'Location') {
            return (
                <>
                    <select className="form-control" required="" onChange={(e) => handleC5Change(e.target.value)}>
                        <option value="">Select</option>
                        {c4Data?.map((i, j) => {
                            return (<option value={i}>{i}</option>)
                        })}
                    </select>
                </>
            )
        }
    }

    const showEvaluateForm = () => {
        setIsLoadingEvaluate(true);
        // Simulate a loading process
        setTimeout(() => {
            // Your loading logic here
            setIsLoadingEvaluate(false); // Once loading is done
        }, 2000); // Simulate a 2-second loading time

        handleAddEvaluation(1);
        // if (defaultValueC1 === '' || defaultValueC2 === '' || defaultValueC3 === '' || defaultValueC4 === '' || autoGeneratedId === '' || callDateTime === '' || formData[0].display_name === '' || affiliationValue === '' || evaluationStatus === '') {
        //     ErrorNotify('Please fill all required values');
        //     return;
        // }else {
        //     return (
        //         <Link to='form-preview'></Link>
        //     )
        // }
    }



    // const dropdown = document.getElementById('myDropdown');
    // const searchInput = document.createElement('input');
    // searchInput.type = 'text';
    // searchInput.placeholder = 'Search...';

    // dropdown.addEventListener('click', function () {
    //     this.appendChild(searchInput);
    //     searchInput.focus();
    // });

    // searchInput.addEventListener('keyup', function () {
    //     const filter = this.value.toLowerCase();
    //     const options = dropdown.getElementsByTagName('option');

    //     for (let i = 0; i < options.length; i++) {
    //         const optionText = options[i].textContent.toLowerCase();
    //         if (optionText.indexOf(filter) > -1) {
    //             options[i].style.display = '';
    //         } else {
    //             options[i].style.display = 'none';
    //         }
    //     }
    // });
    if (!c1Data.includes("Select")) {
        c1Data.unshift("Select")
    }
    if (!c2Data.includes("Select")) {
        c2Data.unshift("Select")
    }
    if (!c3Data.includes("Select")) {
        c3Data.unshift("Select")
    }
    if (!c4Data.includes("Select")) {
        c4Data.unshift("Select")
    }
    // if (!otherEvaluatorsData.includes("Select")) {
    //     otherEvaluatorsData.unshift("Select")
    // }

    const optionsC1 = c1Data?.map((item) => ({
        value: item,
        label: item
    }));
    const optionsC2 = c2Data?.map((item) => ({
        value: item,
        label: item
    }));
    const optionsC3 = c3Data?.map((item) => ({
        value: item,
        label: item
    }));

    const optionsC4 = c4Data?.map((item) => ({
        value: item,
        label: item
    }));


    const optionsFormData = formData?.map((i) => ({
        value: `${i.form_name}||${i.form_version}`,
        label: `${i.display_name}(V${i.form_version}.0)`
    }));
    const optionsotherEvaluatorsData = otherEvaluatorsData.map((i) => ({
        value: `${i.agent_id}`,
        label: `${i.agent_name_id}`
    }));
    const optionAssign = [
        { value: '', label: 'Select' },
        { value: 'none', label: 'None' },
        { value: 'myself', label: 'Self' },
        { value: 'other', label: 'Other' }
    ]

    const optionChannel = [
        { value: '', label: 'Select' },
        { value: 'chat', label: 'Chat' },
        { value: 'audio', label: 'Audio' },
    ]

    const optionMedia = [
        { value: '', label: 'Select' },
        { value: 'no', label: 'No' },
        { value: 'yes', label: 'Yes' }
    ]




    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <main>
                <ToastContainer />
                {/* <ErrorNotify successMsg = "success" /> */}
                <div className="main-contents">
                    <div className="d-none" id="success_section">
                        <div className="page_error success mb-12">
                            <div className="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div className="d-none" id="failure_section">
                        <div className="page_error failure mb-12">
                            <div className="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button className="remove_error_msg"></button>
                        </div>
                    </div>
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Add Call</span></h3>
                            </div>
                        </div>
                    </div>
                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="#">Call</a></li>
                            <li className="breadcrumb-item active"><span>Add Call</span></li>
                        </ul>
                    </nav>

                    <div className="ip-section">
                        <div className="container-fluid">
                            <div className="ip-form p-0">
                                <form action="" method="post" encType="multipart/form-data">
                                    <div className="form-contents call_register_call">
                                        <div className="row">
                                        <div className="col-sm-6 col-md-4 col-lg-3 rounded form-group">
                                        <label><span>Channel</span><span className="mandatory">*</span></label>
                                        <Select
                                                    options={optionChannel}
                                                    id="reset"
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    onChange={(e) => setChannelType(e.value)}
                                                />
                                        </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 rounded form-group">
                                                <label><span>{hierarchyData.c1}</span><span className="mandatory">*</span></label>

                                                {(url_lob === undefined || url_lob === null) ?


                                                    <Select options={optionsC1}
                                                        value={defaultValueC1 !== '' ? optionsC1.find((option) => option.value === defaultValueC1) : ''}
                                                        isSearchable={true} required={true} onChange={(e) => handleC2Change(e)}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px',
                                                                boxShadow: 'none', // Remove the blue box shadow
                                                                borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                                '&:hover': {
                                                                    borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                                },
                                                                '&:focus': {
                                                                    outline: 'none', // Remove the blue outline on focus
                                                                    borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                    boxShadow: 'none', // Remove the blue box shadow on focus
                                                                },
                                                                '&:active': {
                                                                    borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                    boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                                },
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                                color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                            }),

                                                        }}
                                                    />
                                                    :
                                                    <input type="text" className="form-control" placeholder='Supervisor Name' required="true" value={url_lob && url_lob} disabled='disabled' />
                                                }
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 rounded form-group">
                                                <label><span>{hierarchyData.c2}</span><span className="mandatory">*</span></label>

                                                {(url_campaign === undefined || url_campaign === null) ?

                                                    <Select options={optionsC2}
                                                        id="optionC2Id"
                                                        value={defaultValueC2 !== '' ? optionsC2.find((option) => option.value === defaultValueC2) : ''}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px',
                                                                boxShadow: 'none', // Remove the blue box shadow
                                                                borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                                '&:hover': {
                                                                    borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                                },
                                                                '&:focus': {
                                                                    outline: 'none', // Remove the blue outline on focus
                                                                    borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                    boxShadow: 'none', // Remove the blue box shadow on focus
                                                                },
                                                                '&:active': {
                                                                    borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                    boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                                },
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                                color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                            }),

                                                        }}
                                                        isSearchable={true} required={true} ref={selectRef} onChange={(e) => handleC3Change(e)} />
                                                    :
                                                    <input type="text" className="form-control" placeholder='Supervisor Name' required value={url_campaign && url_campaign} disabled='disabled' />
                                                }
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 rounded form-group">
                                                <label><span>{hierarchyData.c3}</span><span className="mandatory ">*</span></label>

                                                {(url_vender === undefined || url_vender === null) ?
                                                    // <select onChange={(e) => handleC4Change(e.target.value)} className="form-control" required="" >
                                                    //     <option value="" defaultValue="" >Select</option>
                                                    //     {c3Data.map((i, j) => {
                                                    //         return (
                                                    //             <option value={i}>{i}</option>
                                                    //         )
                                                    //     })}
                                                    // </select>
                                                    <Select options={optionsC3}
                                                        value={defaultValueC3 !== '' ? optionsC3.find((option) => option.value === defaultValueC3) : ''}
                                                        id="optionsC3Id"
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px',
                                                                boxShadow: 'none', // Remove the blue box shadow
                                                                borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                                '&:hover': {
                                                                    borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                                },
                                                                '&:focus': {
                                                                    outline: 'none', // Remove the blue outline on focus
                                                                    borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                    boxShadow: 'none', // Remove the blue box shadow on focus
                                                                },
                                                                '&:active': {
                                                                    borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                    boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                                },
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                                color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                            }),

                                                        }}

                                                        isSearchable={true} required={true} onChange={(e) => handleC4Change(e)} />
                                                    :
                                                    <input type="text" className="form-control" placeholder='Supervisor Name' required value={url_vender && url_vender} disabled='disabled' />
                                                }
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 rounded form-group">
                                                <label><span>{hierarchyData.c4}</span><span className="mandatory">*</span></label>

                                                {(url_location === undefined || url_location === null) ?
                                                    // <select className="form-control" required="" onChange={(e) => handleC5Change(e.target.value)} >
                                                    //     <option value="">Select</option>
                                                    //     {c4Data.map((i, j) => {
                                                    //         return (<option value={i}>{i}</option>)
                                                    //     })}
                                                    // </select>
                                                    <Select options={optionsC4}
                                                        value={defaultValueC4 !== '' ? optionsC4.find((option) => option.value === defaultValueC4) : ''}
                                                        id="optionsC4Id"
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px',
                                                                boxShadow: 'none', // Remove the blue box shadow
                                                                borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                                '&:hover': {
                                                                    borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                                },
                                                                '&:focus': {
                                                                    outline: 'none', // Remove the blue outline on focus
                                                                    borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                    boxShadow: 'none', // Remove the blue box shadow on focus
                                                                },
                                                                '&:active': {
                                                                    borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                    boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                                },
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                                color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                            }),

                                                        }}
                                                        isSearchable={true} required={true} onChange={(e) => handleC5Change(e)} />
                                                    :
                                                    <input type="text" className="form-control" placeholder='Supervisor Name' required value={url_location && url_location} disabled='disabled' />
                                                }
                                            </div>

                                            <div className="col-sm-6 col-md-4 col-lg-3 rounded form-group">
                                                <label><span>Form</span><span className="mandatory">*</span></label>
                                                {/* <select className="form-control" required="" onChange={(e) => setFormName(e.target.value)}>
                                                    <option value="">Select</option>
                                                    {formData.map((i, j) => {
                                                        return (<option value={`${i.form_name}||${i.form_version}`}>{i.display_name}{' (V '}{i.form_version}{'.0)'}</option>)
                                                    })}
                                                </select> */}
                                                <Select options={optionsFormData}
                                                    value={formName !== '' ? optionsFormData.find((option) => option.value === formName) : ''}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    isSearchable={true} required={true} onChange={(e) => setFormName(e.value)} />
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                <div className="position-relative">
                                                    <label><span>Agent Name (ID)</span><span className="mandatory">*</span></label>
                                                    {/* <AutoComplete getAgentSupervisors={getAgentSupervisors} c1={(url_lob === undefined || url_lob === null) ? url_lob : defaultValueC1} c2={(url_campaign === undefined || url_campaign === null) ? url_campaign : defaultValueC2} c3={(url_vender === null) ? url_vender : defaultValueC3} c4={(url_location === undefined || url_location === null) ? url_location : defaultValueC4} userId={localStorage.getItem('loginUserUniqueid')} /> */}
                                                    <AutoComplete agentname={data_by_call_id && data_by_call_id[0]?.call_meta_data?.length > 0 && data_by_call_id &&
                                                        data_by_call_id && data_by_call_id[0]?.call_meta_data?.agentname === "" ? "" :
                                                        data_by_call_id && data_by_call_id[0]?.call_meta_data?.agentname}
                                                        nameOfAgent={nameOfAgent}
                                                        setNameOfAgent={setNameOfAgent}
                                                        input={input}
                                                        setInput={setInput}
                                                        setAgentData={setAgentData}
                                                        setAgentSupervisors={setAgentSupervisors}
                                                        getAgentSupervisors={getAgentSupervisors} c1={defaultValueC1} c2={defaultValueC2} c3={defaultValueC3} c4={defaultValueC4} userId={localStorage.getItem('loginUserUniqueid')} />
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-4 col-lg-3 form-group d-none">

                                                <label><span>Supervisor Name(ID)</span><span className="mandatory">*</span></label>

                                                {/* <input type="text" className="form-control" placeholder='Enter Agent Name' required value={agentSupervisorName} /> */}

                                                <select className="form-control custom-select resetFilter" required>
                                                    <option value="" defaultValue disabled>Select</option>
                                                </select>

                                            </div>

                                            <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                <label><span>Supervisor Name(ID)</span></label>
                                                <input type="text" className="form-control" placeholder='Supervisor Name' required
                                                    onChange={(e) => setAgentSupervisors(e.target.value)}
                                                    disabled
                                                    defaultValue={data_by_call_id && data_by_call_id[0]?.call_meta_data?.length > 0 ?
                                                        data_by_call_id && data_by_call_id[0]?.call_meta_data?.supervisorname === "" ?
                                                            "" : data_by_call_id[0]?.call_meta_data?.supervisorname
                                                        :
                                                        agentSupervisor
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                <label><span>Call Date & Time</span><span className="mandatory">*</span></label>
                                                {/* <Timer setHoursVal={setHoursVal}/> */}
                                                <input step={1} type="datetime-local" max={`${moment().format('YYYY-MM-DD')}T00:00:00`} className="form-control " autoComplete="off" id="callDateTimeInput"
                                                    placeholder="Call Date & Time" required value={callDateTime}
                                                    // onChange={(e) => { setCallDateTime(e.target.value); handleFutureDate(e.target.value) }} 
                                                    onChange={(e) => {
                                                        const newDateTime = e.target.value;

                                                        // Check if the input value includes seconds (ss)
                                                        if (newDateTime.length === "YYYY-MM-DDTHH:mm:ss".length) {
                                                            setCallDateTime(newDateTime);
                                                            handleFutureDate(newDateTime);
                                                        } else {

                                                            setCallDateTime(newDateTime);
                                                        }
                                                    }}
                                                />
                                                {console.log("setCallDateTime111", url_call_id)}
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                <label><span>Call/CHAT ID</span><span className="mandatory">*</span><button type="button" className="btn-auto" onClick={()=>setAutoGeneratedIdFunc()} disabled={(url_call_id !== null && url_call_id !== undefined) ? 'disabled':''} >Auto Generate</button> </label>
                                                <input type="text" className="form-control" placeholder="Call ID" required onInput={(e) => setAutoGeneratedId(e.target.value)} value={(url_call_id !== null && url_call_id !== undefined) ? url_call_id : autoGeneratedId} disabled={callChatIdDisabled} />
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 form-group d-none">
                                                <label><span>Call Recording ID</span></label>
                                                <input type="text" className="form-control" placeholder="Call Recording ID" maxLength="50" />
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 form-group duration-group">
                                                <label><span>Call Duration</span><span className="mandatory">*</span></label>



                                                <div className="">
                                                    <input
                                                        id="timeElementInput"
                                                        type="text"
                                                        className="form-control custom-timepicker pull-right"
                                                        // value={timeStr !== '00:00:00' ? timeStr : ''}
                                                        // defaultValue={'00:00:00'}
                                                        // value={`${hours || '00'}:${minutes || '00'}:${seconds || '00'}`}
                                                        placeholder="HH:MM:SS"
                                                        autoComplete="off"
                                                        maxLength="8" // Set maximum length to 8 to include colons
                                                        onFocus={(e) => {
                                                            // Clear the input field when it is clicked (on focus)
                                                            if (e.target.value === currentTime || e.target.value === '00:00:00') {
                                                                e.target.value = '';
                                                            }
                                                            toggleDropdown();
                                                            console.log("DUdtssaasd", currentTime)
                                                        }}
                                                        onBlur={(e) => {
                                                            const enteredTime = e.target.value;
                                                            const [inputHours, inputMinutes, inputSeconds] = enteredTime.split(':');

                                                            // Set the state variables with the user-entered values
                                                            setHoursValue(inputHours);
                                                            setMinutesValue(inputMinutes);
                                                            setSecondValue(inputSeconds);
                                                        }}
                                                        onInput={(e) => {
                                                            // Restrict input to numbers and colons only
                                                            e.target.value = e.target.value.replace(/[^0-9:]/g, '');

                                                            // Remove colons from the value
                                                            const numericValue = e.target.value.replace(/:/g, '');

                                                            // Truncate the input if it exceeds 6 numeric characters
                                                            if (numericValue.length > 6) {
                                                                e.target.value = numericValue.slice(0, 6).replace(/(\d{2})(?=\d)/g, '$1:');
                                                            }

                                                            setHoursValue(e.target.value.slice(0, 2));
                                                            setMinutesValue(e.target.value.slice(3, 5));
                                                            setSecondValue(e.target.value.slice(6));
                                                        }}
                                                    />
                                                </div>


                                                {/* <div className="col col-sm-6 col-md-4 col-lg-3 form-group duration-group">
                                                    <label><span>Call Duration</span></label>
                                                    <input type="text" className="form-control custom-timepicker" defaultValue="" autoComplete="off" value={} onBlur={(e) => setCallDuration(e.target.value)} />
                                                </div> */}



                                                {/* <div className="timer">
                                                <div className="form-control ">
                                                        <input type='text'
                                                        id="hoursInput"
                                                        onFocus={() => hours==="HH"&&setHoursValue('')} 
                                                        onBlur={()=>hours?.trim()===""&&setHoursValue(data_by_call_id&&data_by_call_id[0]?.duration.split(":")[0])}
                                                        onInput={(e) => {setHoursValue(e.target.value);handleTime()}} 
                                                        
                                                        value={hours} style={{ border: 'none', width: '25px' }} />:
                                                        <input type='text'
                                                        id="minutesInput" 
                                                        onFocus={() => minutes==="MM"&&setMinutesValue('')} 
                                                        onBlur={()=>minutes?.trim()===""&&setMinutesValue(data_by_call_id&&data_by_call_id[0]?.duration.split(":")[1])}
                                                        onInput={(e) => {setMinutesValue(e.target.value);handleTime()}} 
                                                        value={minutes} style={{ border: 'none', width: '26px' }} />:
                                                        <input type='text'
                                                        id="secondsInput" 
                                                        onFocus={() => seconds==="SS"&&setSecondValue('')} 
                                                        onBlur={()=>seconds?.trim()===""&&setSecondValue(data_by_call_id&&data_by_call_id[0]?.duration.split(":")[2])}
                                                        onInput={(e) => {setSecondValue(e.target.value);handleTime()}} value={seconds} style={{ border: 'none', width: '25px' }} />
                                                    </div>
                                                </div>  */}


                                                {/* <span contentEditable={true} onInput={(e) => setMinutes(e.target.value)}>{minutes}</span>:<span contentEditable={true} onInput={(e) => setSeconds(e.target.value)}>{seconds}</span> */}
                                                {/* <input type="time" className="form-control  duration-field" placeholder="Call Duration" required value={callDuration} onInput={(e) => setCallDuration(e.target.value)} /> */}
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 form-group">

                                                <label><span>Assign To</span><span className="mandatory">*</span></label>

                                                <Select
                                                    options={optionAssign}
                                                    value={assignValue !== '' ? optionAssign.find((option) => option.value === assignValue) : ''}
                                                    id="reset"
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    onChange={(e) => hideEvaluateBtn(e.value)}
                                                />

                                            </div>

                                            {<div className={allClasses}>
                                                <label><span>Evaluator Name(ID)</span><span className="mandatory">*</span></label>
                                                {/* <select className="form-control" required="" onChange={(e) => setEvaluatorName(e.target.value)}>
                                                    <option value="">Select</option>
                                                    {otherEvaluatorsData.map((i, j) => {
                                                        return (<option value={i.agent_id}>{i.agent_name_id

                                                        }</option>)
                                                    })}
                                                </select> */}
                                                <Select options={optionsotherEvaluatorsData}
                                                    value={evaluatorName !== '' ? optionsotherEvaluatorsData.find((option) => option.value === evaluatorName) : ''}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    isSearchable={true} required={true} onChange={(e) => setEvaluatorName(e.value)} />

                                            </div>}


                                            <div id="eval_sup_2" className="col-sm-6 col-md-4 col-lg-3 form-group d-none">
                                                <label><span>Evaluator Supervisor Name(ID)</span><span className="mandatory">*</span></label>

                                                <select className="form-control" required="">
                                                    <option disabled value="">Select</option>
                                                    {otherEvaluatorsData.map((i, j) => {
                                                        return (<option value={i.agent_id}>{i.agent_name_id
                                                        }</option>)
                                                    })}
                                                </select>

                                                {/* <select className="form-control custom-select resetFilter" required>
                                                <option value="" defaultValue disabled>Select Evaluator Supervisor ID</option>
                                            </select> */}
                                            </div>
                                            {client_type === 'vonage' && <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                <label><span>Affiliation</span><span className="mandatory">*</span></label>
                                                <select className="form-control resetFilter" required onChange={(e) => setAffiliationValue(e.target.value)}>
                                                    <option defaultValue="">Select </option>
                                                    <option defaultValue='MattsenKumar'>MattsenKumar</option>
                                                    <option defaultValue='CenturyLink'>CenturyLink</option>
                                                </select>
                                            </div>}

                                            <div className="col-sm-6 col-md-4 col-lg-3 form-group d-none">
                                                <label><span>Media</span><span className="mandatory">*</span></label>

                                                <Select
                                                    className=""
                                                    options={optionMedia}
                                                    value={mediaValue !== '' ? optionMedia.find((option) => option.value === mediaValue) : ''}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                                borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                                outline: 'none', // Remove the blue outline on focus
                                                                borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                                borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                        }),

                                                    }}
                                                    required
                                                    onChange={(selectedOption) => {
                                                        handleMediaValue(selectedOption);
                                                    }}
                                                />
                                            </div>

                                            <div className={`col-sm-6 col-md-6 col-lg-6 form-group ${(mediaValue === 'yes') ? ' ' : 'd-none'}`}>
                                                <div className="mb-1"><label ><span>Upload File</span><span className="mandatory">*</span></label></div>
                                                <div className=" form-control border border-black  p-2">
                                                    <div className="ml-2">
                                                        <input ref={fileInputRef} className="" type="file" accept="audio/wav" name="shadow" required="true" onChange={(e) => [setFile(e.target.files[0]), setFileName(true)]} />
                                                    </div>
                                                </div>

                                            </div>




                                        </div>
                                    </div>
                                    <div className="form-footer form-footer text-right d-flex align-items-center">

                                        {/* <button type="reset" className={`btn btn-outline-gray btn-reset mr-3 btnResetFields ${resetBool ? 'loading' : ''} `} onClick={() => handleReset()} >Reset</button> */}
                                        {

                                            (
                                                <div className="position-relative d-flex align-items-center">
                                                    <button
                                                        type="reset"
                                                        className={`btn btn-outline-gray btn-reset mr-3 btnResetFields ${resetBool ? 'loading' : ''}`}
                                                        onClick={handleReset}
                                                        disabled={resetBool}

                                                    >
                                                        Reset
                                                    </button>
                                                    {resetBool && (
                                                        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center opacity-75">
                                                            <div className="spinner-border text-black" role="status">

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }

                                        <div>

                                            {showEvaluateBtn &&
                                                // <button type="button" className="btn btn-outline-primary" onClick={showEvaluateForm}>Evaluate</button>
                                                (
                                                    <div className="position-relative d-flex align-items-center">
                                                        <button
                                                            type="button"
                                                            className={`btn btn-outline-primary`}
                                                            onClick={showEvaluateForm}
                                                            disabled={isLoadingEvaluate}

                                                        >
                                                            Evaluate
                                                        </button>
                                                        {isLoadingEvaluate && (
                                                            <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center opacity-75">
                                                                <div className="spinner-border text-black" role="status">

                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <button onClick={() => handleAddEvaluation(0)} type="button" className={`btn btn-primary alpha ml-auto ${isDataFound ? 'loading' : ''} `}>
                                            <span>Register</span>
                                            <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {isDataFound && <div className="page-loader align-items-center justify-content-center loading">
                    <div className="loader-dots d-flex align-items-center justify-content-center">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>}

            </main>
            {/* page loader */}



            {/* page loader */}
            {/* <Footer /> */}
        </>
    )
}

export default AddEvaluation;