import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { ErrorNotify } from "../../Toast";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

// Chart hover label plugin
const hoverLabel = {
    id: "hoverLabel",
    afterDraw: (chart) => {
      const { ctx, chartArea, _metasets } = chart;
  
      if (_metasets?.length && chart.tooltip?._active?.[0]) {
        const activeElement = chart.tooltip._active[0];
        const { datasetIndex, index } = activeElement;
  
        // Get value and total
        const value = _metasets[datasetIndex]._parsed[index];
        const total = _metasets[datasetIndex]?.total;
  
        // Handle zero or invalid values for percentage calculation
        let percentage = 0;
        if (total > 0 && value !== undefined) {
          percentage = (value / total) * 100;
        }
  
        // Format percentage to 2 decimal places
        percentage = percentage.toFixed(2);
  
        // Get chart dimensions
        const centerX = (chartArea.left + chartArea.right) / 2;
        const centerY = (chartArea.top + chartArea.bottom) / 2;
  
        // Draw text
        // ctx.save();
        // ctx.font = "bold 20px sans-serif"; // Adjust size and style as needed
        // ctx.fillStyle = "#000"; // Text color
        // ctx.textAlign = "center";
        // ctx.textBaseline = "middle";
        // ctx.fillText(`${percentage}%`, centerX, centerY); // Text and position
        // ctx.restore();
      }
    },
  };

  const centerText = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { ctx, chartArea, data, options } = chart;
  
      if (!options.plugins.centerTextEnabled || !chart._metasets.length) return;
  
      // Get dataset values
      const total = data.datasets[0].data.reduce((sum, value) => sum + value, 0);
      const autoFailValue = data.datasets[0].data[0] || 0;   // Assuming autofail is the first value
      const nonAutoFailValue = total - autoFailValue;
  
      let displayValue;
      if (nonAutoFailValue === 100 && autoFailValue === 0) {
        displayValue = "100%";
      } else {
        const percentage = total > 0 ? ((nonAutoFailValue / total) * 100).toFixed(2) : 0;
        displayValue = `${autoFailValue}%`;
      }
  
      // Get chart center coordinates
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
  
      // Draw text in the center
      ctx.save();
      ctx.font = "bold 20px sans-serif";
      ctx.fillStyle = "#000";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(displayValue, centerX, centerY);
      ctx.restore();
    },
  };
  
  
  

// ChartJS registration
ChartJS.register(ArcElement, Tooltip, Legend, hoverLabel,centerText);

const AutoFailScoreChart = ({
  fromDate,
  toDate,
  dateRange,
  autoFailScoreData,
  setAutoFailScoreData,
  filterStatus,
  formValVersion,
  fromDateForDash,toDateForDash,dateTypeForDash,latestForm
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);
  const [chartData, setChartData] = useState({});
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const noDataChartData = {
    labels: ["Not Available"],
    datasets: [
      {
        data: [1],
        backgroundColor: ["rgba(200, 200, 200, 0.5)"],
        borderWidth: 0.1,
        radius: "100%",
      },
    ],
  };
  useEffect(() => {
    if(latestForm?.label!==undefined&&latestForm?.value?.split("||")[0]!==undefined){
    if (typeof formValVersion !== "string" && formValVersion?.form_name && formValVersion?.form_version) {
      const url = `${base_url}dashboard/auto-fail`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
        },
        body: JSON.stringify({
         'start_date': fromDateForDash,
                'end_date': toDateForDash,
                'daterange': dateRange,
                'date_type':dateTypeForDash,
         'form':process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "recruitment" ?[latestForm?.value]:[`${formValVersion?.form_name}||${formValVersion?.form_version}`],
          proxy_user_id:
            JSON.parse(localStorage.getItem("settedProxy"))?.value || "",
          proxy_by: localStorage.getItem("settedProxy")
            ? localStorage.getItem("loginUserUniqueid")
            : "",
        }),
      };

      fetchData(url, requestOptions);
    }
  }
  }, [dateRange, formValVersion]);

  useEffect(() => {
    if (autoFailScoreData?.length > 0) {
      const labels = [];
      const dataValues = [];
      autoFailScoreData.forEach((item) => {
        labels.push(item.label);
        dataValues.push(parseFloat(item.value.replace("%", "")) || 0);
      });

      setChartData({
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: [
              "rgba(41, 195, 190, 0.5)",
              "rgba(93, 98, 181, 0.5)",
            ],
            borderWidth: 0.1,
            radius: "100%",
          },
        ],
      });
      setIsDataAvailable(true);
      setIsLoading(false);
    } else {
      setIsDataAvailable(false);
      setIsLoading(false);
    }
  }, [autoFailScoreData]);

  const fetchData = async (url, requestOptions) => {
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();

      if (data.status === 200) {
        setAutoFailScoreData(data.data);
      } else {
        handleError(data.message);
      }
    } catch (error) {
      handleError("Something went wrong. Please contact the administrator!");
    }
  };

  const handleError = (message) => {
    setErrorMsg(true);
    setIsLoading(false);
    ErrorNotify(message, { icon: "error" });
  };

  return (
    <div className="col-sm-6 col-md-6 col-lg-6">
      <div
        id="zoom_chart2"
        className="chart-card p-4"
        style={{ height: "365px" }}
      >
        <div className="chart-header d-flex justify-content-between align-items-center">
          <h4>Auto-Fail Score</h4>
          <div>
            <button
              className="chart_zoomin_btn"
              onclick="openFullscreen('zoom_chart2')"
            >
              Zoom In
            </button>
            <button
              className="chart_zoomout_btn"
              onclick="openFullscreen('zoom_chart2');"
            >
              Zoom Out
            </button>
          </div>
        </div>
        {isLoading ? (
          <div id="qa_score_guage2" className="guage_cont toggle_chart active chart_contents" style={{
            height: '245px',
            textAlign: 'center',
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '0',
            top: '50%'
        }}>
            <span className={`${isLoading ? 'loading' : ''}`} id="passrateid">
                <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
            </span>
        </div>
        ) : isDataAvailable ? (
          <Doughnut
            data={chartData}
            options={{
              plugins: { hoverLabel,centerTextEnabled: true, },
              elements: { arc: { borderWidth: 10 } },
            }}
            
            style={{ margin: "auto", marginTop: "-25px" }}
            
          />
        ) : (
            <Doughnut
            data={noDataChartData}
            options={{
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    color: "rgba(100, 100, 100, 0.5)",
                  },
                },
              },
              id: "autoFail",
              elements: { arc: { borderWidth: 10 } },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: () => "No Data Available",
                  },
                },
              },
            }}
            style={{ margin: "auto" }}
          />
        )}
      </div>
    </div>
  );
};

export default AutoFailScoreChart;
