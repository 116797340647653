import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin);

// 🔹 Static Data for Fixed Stacked Bar
const STATIC_BAR_DATA = {
  "predicted score 1": 20,
  "predicted score 2": 15,
  "predicted score 3": 20,
  "predicted score 4": 25,
  "predicted score 5": 20,
};

const StackedHorizontalBar = ({ isStatic, barData, markerValue }) => {
  console.log("🔹 Received markerValue:", markerValue, "isStatic:", isStatic);

  // 🔹 Use Static Data if isStatic=true, otherwise use API Data
  const chartData = isStatic ? STATIC_BAR_DATA : barData?.[0] || {};

  // Extract keys and values
  const categories = Object.keys(chartData);
  const values = categories.map((key) => parseFloat(chartData[key]) || 0);

  // 🔹 Compute Normalized Marker Position (Only for Static Charts)
  let markerXPosition = null;
  if (isStatic) {
    const cumulativeValues = values.reduce((acc, val) => {
      acc.push((acc.length ? acc[acc.length - 1] : 0) + val);
      return acc;
    }, []);

    console.log("✅ Cumulative Values:", cumulativeValues);

    // 🔹 Normalize marker value relative to total
    const totalSum = cumulativeValues[cumulativeValues.length - 1];

    if (markerValue > 0 && markerValue <= totalSum) {
      markerXPosition = (markerValue / totalSum) * 100; // Convert to percentage
    }
  }

  console.log("✅ Normalized Marker X Position:", markerXPosition);

  // Define colors for the stacked segments
  const stackColors = ["#a0d683", "#ffa366", "#d3ee98", "#ffcc00", "#ff6699"];
  const stackStaticColors = ["#ffa366", "#ffc299", "#ffddae", "#d3ee98", "#a0d683"];


  // Memoize chart data
  const data = useMemo(() => ({
    labels: ["Category 1"],
    datasets: categories.map((key, index) => ({
      label: key,
      data: [values[index]],
      backgroundColor: isStatic ? stackStaticColors[index]: stackColors[index] || "#000",
    })),
  }), [barData, isStatic]);

  // Memoize chart options
  const options = useMemo(() => ({
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: { bottom: 2 } },
    plugins: {
      legend: {
        display: !isStatic ? true:false,
        position: "bottom",
        labels: {
          font: { size: 9 },
          boxWidth: 10,
          padding: 2,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => {
            const datasetIndex = tooltipItem.datasetIndex;
            const segmentValue = `${categories[datasetIndex]}: ${tooltipItem.raw}%`;

            // Append marker value to tooltip if it's a static chart
            if (isStatic && markerValue) {
              return `Confidence Level : ${markerValue}%`;
            }
            return segmentValue;
          },
          title: () => null,
        },
        titleFont: { size: 9},
        bodyFont: { size: 9 }
      },
      datalabels: {
        color: "#232424", // Set label color
        font: { size: 9, weight: "normal" }, // Adjust font size
        anchor: "center", // Center the label inside the bar
        align: "center",
        formatter: (value, context) => {
          if (!isStatic) return ""; // Show only for static charts
      
          const total = values.reduce((sum, val) => sum + val, 0);
          const percentage = ((value / total) * 100); // Calculate percentage
      
          return `${percentage}%`; // Show percentage value
        },
      },      
      ...(isStatic && markerXPosition !== null && {
        annotation: {
          annotations: {
            line: {
              type: "line",
              xMin: markerXPosition,
              xMax: markerXPosition,
              borderColor: "red",
              borderWidth: 2,
              label: {
                enabled: true,
                content: `${markerValue}%`,
                position: "end",
                backgroundColor: "rgba(255, 0, 0, 0.5)",
              },
            },
          },
        },
      }),
    },
    scales: {
      x: { display: false, stacked: true,barThickness:5 },
      y: { display: false, stacked: true,barThickness:5},
    },
  }), [barData, isStatic, markerXPosition]);

  return (
    <div style={{ height: `${isStatic ? "20px":"30px"}`, width: "300px" }}>
      <Bar data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default StackedHorizontalBar;
