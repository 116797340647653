import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { ErrorNotify } from "../../Toast";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const RecruiterAttributeScore = ({
  fromDate,
  toDate,
  dateRange,
  latestForm,
  formValVersion,
  fromDateForDash,
  setRecruitVerdictData,
  recruitVerdictData,
  toDateForDash,
  dateTypeForDash,
  filterStatus
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [visibleLegends, setVisibleLegends] = useState({
    Good: true,
    Bad: true,
    Neutral: true,
    Positive: true,
  });

  useEffect(() => {
    if(latestForm?.label!==undefined&&latestForm?.value?.split("||")[0]!==undefined){
    if (
      typeof formValVersion !== "string" &&
      formValVersion?.form_name &&
      formValVersion?.form_version
    ) {
      const url = `${base_url}dashboard-ai/call-verdict`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          start_date: fromDateForDash,
          end_date: toDateForDash,
          daterange: dateRange,
          date_type: dateTypeForDash,
          form:
            process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "recruitment"
              ?  [latestForm?.value]
              : [`${formValVersion?.form_name}||${formValVersion?.form_version}`],
          proxy_user_id: localStorage.getItem("settedProxy")
            ? JSON.parse(localStorage.getItem("settedProxy"))?.value
            : "",
          proxy_by: localStorage.getItem("settedProxy")
            ? localStorage.getItem("loginUserUniqueid")
            : "",
        }),
      };
      setIsLoading(true);
      setRecruitVerdictData([]);
      setChartData(null);
      callApi(url, requestOptions, "call-verdict");
    }
  }
  }, [dateRange, formValVersion,filterStatus]);

  const callApi = async (url, requestOptions, flag) => {
    try {
      const res = await fetch(url, requestOptions);
      const data = await res.json();

      if (flag === "call-verdict") {
        if (data.status === 200) {
          setRecruitVerdictData(data.data);
          setIsLoading(false);
        } else {
          handleApiError(data);
        }
      }
    } catch (err) {
      setIsLoading(false);
      ErrorNotify("Something went wrong. Please contact the Administrator!", {
        icon: "error",
      });
    }
  };

  const handleApiError = (data) => {
    if (data?.status === 401) {
      localStorage.clear();
    }
    setIsLoading(false);
    ErrorNotify(data.message, { icon: "error" });
  };

  const toggleLegend = (legendName) => {
    setVisibleLegends((prevState) => ({
      ...prevState,
      [legendName]: !prevState[legendName],
    }));
  };

  useEffect(() => {
    if (Array.isArray(recruitVerdictData) && recruitVerdictData.length > 0) {
      const categories = recruitVerdictData.map((agent) => ({
        label: agent.Agent_name,
      }));

      const callVerdictTypes = ["Good", "Bad", "Neutral", "Positive"];
      const colors = ["#4BC0C0", "#FF6384", "#C9CBCE", "#FFCE56"];

      const dataset = callVerdictTypes
        .filter((verdict) => visibleLegends[verdict]) // Filter based on legend visibility
        .map((verdict, index) => ({
          seriesname: verdict,
          color: colors[index],
          data: recruitVerdictData.map((agent) => ({
            value: agent.callVerdict?.[0]?.[verdict]
              ? parseFloat(agent.callVerdict[0][verdict])
              : 0,
          })),
        }));

      setChartData({
        chart: {
          caption: "Recruiter Wise Call Verdict",
          yAxisName: "Score (%)",
          theme: "fusion",
          yAxisMinValue: "0",
          yAxisMaxValue: "100",
          // plotToolText: "<b>$label</b>: $dataValue% ($seriesName)",
          plotToolText: "<b>$seriesName</b>: $dataValue%",
          showValues: "0",
          scrollHeight: "15",
          showHoverEffect: "1",
          
    
   
        },
        categories: [
          {
            category: categories,
          },
        ],
        dataset: dataset,
        
      });
    }
  }, [recruitVerdictData, visibleLegends]);

  return (
    <div id="zoom_chart5" className="chart-card p-4">
      <div className="chart-header">
        {!chartData && (
          <h4 style={{ textAlign: "center" }}>Recruiter Wise Call Verdict</h4>
        )}
      </div>

      {/* Custom Legend */}
     

      {isLoading ? (
        <div
          id="custom4_p"
          className="chart_contents"
          style={{
            height: "250px",
            textAlign: "center",
            position: "absolute",
            left: "0",
            right: "0",
            bottom: "0",
            top: "50%",
          }}
        >
          <div
            className={isLoading ? "loading" : ""}
            id="passrateid"
            style={{ height: "200px" }}
          >
            <span className="loader">
              <span className="spinner-border" role="status" aria-hidden="true"></span>
            </span>
          </div>
        </div>
      ) : chartData ? (
        <ReactFC
          type="scrollstackedcolumn2d"
          width="100%"
          height="400"
          dataFormat="json"
          dataSource={chartData}
        />
      ) : (
        <div
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          No Data Available
        </div>
      )}
    </div>
  );
};

export default RecruiterAttributeScore;
