import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ToastContainer } from "react-toastify";
import { ErrorNotify } from "../../Toast";
import moment from "moment";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20,
        bottom: 20
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Evaluation Score (%)',
        position: 'left',
        align: 'middle'
      },
      tooltip: {
        callbacks: {
            label: function (tooltipItem) {
                console.log("tooltipItem",tooltipItem)
                let value = tooltipItem.raw || 0; // Get the value
                return `${value}%`; // Append percentage symbol
            }
        }
    },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45,
        },
        grid: {
          display: false // X-axis grid remove
        }
      },
      y: {
        beginAtZero: false,
        min: 0,
        ticks: {
          callback: function (value) {
            return `${value}%`;
          },
        },
        grid: {
          display: false // Y-axis grid remove
        }
      },
    },
  };
  

const NewEvaluationSummaryChart = ({ fromDate, toDate, dateRange,momentType,setMomentType, evFilterData, formValVersion,fromDateForDash,setdateRangeValue,toDateForDash,dateTypeForDash,latestForm }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  // const [momentType, setMomentType] = useState("Daily");

  useEffect(() => {
    if (typeof formValVersion !== "string" && formValVersion?.form_name && formValVersion?.form_version) {
      setIsLoading(true)
      if(latestForm?.label!==undefined&&latestForm?.value?.split("||")[0]!==undefined){
      fetchChartData();
      }
    }
  }, [formValVersion]);
  

  useEffect(() => {
    if (evFilterData) {
      processChartData(evFilterData);
    }
  }, [evFilterData]);

  const fetchChartData = async (timeType="Daily") => {
    let start_date = "";
  let end_date = "" // Today's date
  setMomentType(timeType)
  // setdateRangeValue(timeType)
  switch (timeType) {
    case "Daily":
      start_date = fromDateForDash; // Today
      end_date =moment().format("YYYY-MM-DD");
      break;
    case "Weekly":
      start_date = moment().subtract(7, "days").format("YYYY-MM-DD"); // Last 7 days
      end_date =moment().format("YYYY-MM-DD");
      break;
    case "Quarterly":
      start_date = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"); // Start of last month
      end_date =moment().format("YYYY-MM-DD");
      break;
    case "Half-yearly":
      start_date = moment().subtract(6, "months").startOf("month").format("YYYY-MM-DD"); // Start of last 6 months
      end_date =moment().format("YYYY-MM-DD");
      break;
    default:
      start_date = fromDateForDash; // Fallback to today
      end_date =toDateForDash
      // setMomentType("Daily")
  }
    setIsLoading(true);
    try {
      const response = await fetch(`${base_url}dashboard/evaluation-summary`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('loginToken')}`
        },
        body: JSON.stringify({
         start_date,
                end_date,
                'daterange': timeType,
                'date_type':dateTypeForDash,
         'form':process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "recruitment" ?[latestForm?.value]:[`${formValVersion?.form_name}||${formValVersion?.form_version}`],
          proxy_user_id: JSON.parse(localStorage.getItem("settedProxy"))?.value || "",
          proxy_by: localStorage.getItem("loginUserUniqueid") || ""
        })
      });
      const data = await response.json();
      if (data.status === 200) {
        processChartData(data.data);
        setIsLoading(false);
      } else {
        handleErrors(data);
      }
    } catch (error) {
      setIsLoading(false);
      ErrorNotify("Something went wrong. Please contact the Administrator!");
    }
  };

  const processChartData = (data) => {
    const newLabels = data?.cat?.map(i => i.label) || [];
    const chartTypes = ['line', 'line', 'line', 'line'];
    const backgroundColors = ['rgb(93,98,181)', 'rgb(41,204,180)', 'rgb(240,96,66)', 'rgb(240,187,86)'];
    const datasetLabels = ['Evaluation', 'Dispute', 'Auto-Fail', 'Coaching'];
    
    const newDatasets = [data?.val1, data?.val2, data?.val3, data?.val4].map((values, index) => ({
      type: chartTypes[index],
      label: datasetLabels[index],
      data: values?.map(i => parseFloat(i.value.replace('%', ''))) || [],
      fill: true,
      backgroundColor: backgroundColors[index],
      borderColor: backgroundColors[index],
      borderWidth: 2,
      tension: 0.3
    }));
    
    setLabels(newLabels);
    setDatasets(newDatasets);
  };

  const handleErrors = (data) => {
    if (data.status === 401) {
      localStorage.clear();
    }
    setIsLoading(false);
    ErrorNotify(data.message);
  };

  return (
    <div className="w-100 p-4">
      <ToastContainer />
      <div className="chart-card p-4 w-100" style={{ height: '350px' }}>
        <div className="chart-header d-flex justify-content-between align-items-center">
          <h4>Evaluation Summary</h4>
          {/* <div className="d-flex gap-2">
            {console.log("momentTypemomentType",momentType)
            }
      <button   className={`btn btn-sm ${momentType === "Daily" ? "btn-primary" : "btn-secondary"} mx-2`}  
      onClick={()=>[fetchChartData("Daily")]} 
      style={{width:"45px",borderRadius:"0px",height:"15px"}}></button>Day
      <button className={`btn btn-sm ${momentType === "Weekly"? "btn-primary" : "btn-secondary"} mx-2`}   
      onClick={()=>[fetchChartData("Weekly")]} 
      style={{width:"45px",borderRadius:"0px",height:"15px"}}></button>Weekly
      <button className={`btn btn-sm ${momentType === "Quarterly"  ? "btn-primary" : "btn-secondary"} mx-2`}  
       onClick={()=>[fetchChartData("Quarterly")]} 
       style={{width:"45px",borderRadius:"0px",height:"15px"}}></button>Quarterly
      <button className={`btn btn-sm ${momentType === "Half-yearly" ? "btn-primary" : "btn-secondary"} mx-2`}  
       onClick={()=>[fetchChartData("Half-yearly")]} 
       style={{width:"45px",borderRadius:"0px",height:"15px"}}></button>6M
    </div> */}
        </div>
        {isLoading ? (
           <div className="chart_contents"
            
           style={{
             textAlign: 'center',
             position: 'absolute',
             left: '0',
             right: '0',
             bottom: '0',
             top: '50%'
         }}>
             <span className="loading">
               <span className="spinner-border" role="status" aria-hidden="true"></span>
             </span>
           </div>
        ) : (
          <Line data={{ labels, datasets }} options={options} />
        )}
      </div>
    </div>
  );
};

export default NewEvaluationSummaryChart;
