/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  useFilters,
  useGlobalFilter,
  useTable,
  usePagination,
  ReactTableDefaults,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import { useLocation } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import XLSX from "xlsx";
import moment from "moment";
import {
  ErrorNotify,
  SuccessNotify,
  DismissToast,
} from "../shared/Toast";
// import 'bootstrap/dist/css/bootstrap.min.css';

import BTable from "react-bootstrap/Table";
import LoadingSpinner from "./LoadingSpinner";
// import ToggleColumns from './ToggleColumns';
import { Link } from "react-router-dom";
import DatatableFilter from "./DatatableFilter";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { components } from "react-select";

// Object.assign(ReactTableDefaults, {
//     multiSort: false,
//     LoadingComponent: LoadingSpinner
// });
var lastRecId = null;
var data2 = "";
var isCoachingSearch = true;
const today = new Date().toISOString().split("T")[0];
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
  }
);


const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows?.length;
  const [value, setValue] = React.useState(globalFilter);
  const [onSearchVal, setOnSearchVal] = React.useState("");
  useEffect(() => {}, [onSearchVal]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  // const onChange = React.useCallback(
  //   useAsyncDebounce((value) => {
  //     setGlobalFilter(value || undefined);
  //   }, 200),
  //   []
  // );
  return (
    // <span>
    //   {
    //     !isCoachingSearch&&

    //   <input
    //     className='form-control my-2 mx-2'
    //     value={value || ""}
    //     onChange={e => {
    //       setValue(e.target.value);

    //       onChange(e.target.value);
    //       data2 = e.target.value
    //       setOnSearchVal(e.target.value)
    //     }}
    //     placeholder={`Search: ${count} records...`}
    //     style={{
    //       fontSize: '1.3rem',
    //     }}
    //   />
    // }
    // </span>
    <></>
  );
};

const DataTableView = ({
  columns,
  data,
  loading,
  setReprocessAppModal,
  universalReprocess,
  title,
  totalRec,
  quickSearch,
  tableSearch,
  evaluatorSearch,
  setAssignCalls,
  filteredColumns,
  userHistoryFilter,
  usersList,
  getUserLoginReport,
  setFilteredColumns,
  exportCalls,
  checkedColumns,
  setCheckedColumns,
  otherEvaluatorsData,
  csvReport,
  setEvaluatorsFunc,
  searchData,
  setCallId,
  setAgentId,
  exportCallListData,
  callListData,
  agentId,
  callId,
  isCSVReport,
  isPDFReport,
  isXLSReport,
  pdfReport,
  xlsReport,
  isAddAlert,
  saveAlertForm,
  isAppFilter,
  isQAAutoFilter,
  isSetting,
  displayCharts,
  SS_SearchApp,
  SS_CreateApp,
  SS_SearchTag,
  SS_CreateTag,
  SS_folderCreate,
  openAppModal,
  openTagModal,
  SSGetAppName,
  isPending,
  isDataFound,
  isTrainingListHeaderButtons,
  isCategoryListHeaderButtons,
  isLessonsListHeaderButtons,
  isTestsListHeaderButtons,
  callListing,
  showEval,
  avalAudit,
  setIsSearchBlank,
  coachingSearch,
  setSearchValue,
  setSearchUniversalValue,
  onlyForForms,
  allData,
  columnDetails,
  setColumnDetails,
  setModalBool,
  isFolderCreation,
  totalExcelExport1,
  totalExcelExport2,
  handleDownloadXLSX1,
  handleDownloadXLSX2,
  handleDownloadPDF1,
  handleDownloadPDF2,
  agentHistory,
  setAgentIdNew,
  handleHistoryAgent,
  setEditDetails,
  setIsEdit,
  addRemoveTableValues,
  searchValue,
  searchValue2
}) => {
  const [agent_id, setAgentId1] = useState("");
  const [call_id, setCallId1] = useState("");

  const [fromDate, setFromDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [userData, setUserData] = useState([]);
  const [userIds, setUserIds] = useState([]);

  useEffect(() => {
    isCoachingSearch = coachingSearch;
  });

  useEffect(()=>{
    gotoPage(0)
  },[searchValue,searchValue2])


  console.log("isLoading",isPending);

  const handleUserListChange = (e) => {
    setUserData(e);
    let userListValue = [];
    e?.map((item) => {
      userListValue.push(item.value);
    });
    setUserIds(userListValue)
    if(!e.length > 0){
      getUserLoginReport(fromDate,toDate)
    }
  };

  const onSubmitFilterReport = () =>{
    console.log("userData",userData)
    if (fromDate === "") {
      ErrorNotify("Please Select From Date");
      return;
    }
    if (toDate === "") {
      ErrorNotify("Please Select To Date");
      return;
    }
    if (!userData?.length > 0) {
      ErrorNotify("Please Select User");
      return;
    }
    getUserLoginReport(fromDate,toDate,userIds)
  }

  const Styles = styled.div`
    padding: 1rem;

    table {
      border-spacing: 0;
      border: 1px solid black;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
          border-right: 0;
        }
      }
    }

    .pagination {
      padding: 0.5rem;
    }
  `;
  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  };
  var role = localStorage.getItem("loginUserRole");
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    getRowId,
    allColumns,
    getToggleHideAllColumnsProps,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    { columns, autoResetPage: false, data },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  setColumnDetails && setColumnDetails(headerGroups[0]?.headers);
  const location = useLocation();
  const path = location.pathname;
  const modifiedString = path.replace(/[_-]/g, "");

  const category_csv_headers = [
    {
      label: "Name",
      key: "cat_name",
    },
    {
      label: "Trainings",
      key: "training",
    },
  ];
  var category_csvReport = {
    data: data,
    headers: category_csv_headers,
    filename: "categories.csv",
  };
  const training_csv_headers = [
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Type",
      key: "type",
    },
    {
      label: "Period",
      key: "period",
    },
    {
      label: "Published Date",
      key: "publish_date",
    },
    {
      label: "Category",
      key: "cat_name",
    },
    {
      label: "Lessons",
      key: "lessons",
    },
  ];
  var training_csvReport = {
    data: data,
    headers: training_csv_headers,
    filename: "trainings.csv",
  };
  const lessons_csv_headers = [
    {
      label: "Title",
      key: "lesson_title",
    },
    {
      label: "Type",
      key: "lesson_type",
    },
    {
      label: "Short Text",
      key: "lesson_desc",
    },
    {
      label: "Training",
      key: "training_name",
    },
    {
      label: "Category",
      key: "cat_name",
    },
    {
      label: "Lesson File",
      key: "lesson_upload",
    },
  ];
  var lessons_csvReport = {
    data: data,
    headers: lessons_csv_headers,
    filename: "lessons.csv",
  };
  const test_csv_headers = [
    {
      label: "Category",
      key: "cat_name",
    },
    {
      label: "Training",
      key: "training_name",
    },
    {
      label: "Title",
      key: "test_title", // accessor is the "key" in the data
    },
    {
      label: "Noof questions",
      key: "no_of_questions", // accessor is the "key" in the data
    },
  ];
  var test_csvReport = {
    data: data,
    headers: test_csv_headers,
    filename: "tests.csv",
  };
  const [allColumnsData, setAllColumnsData] = useState(allColumns);
  const [dataCountTable, setDataCountTable] = useState([]);
  const totalPages = Math.ceil(totalRec / pageSize);

  const exportPDF = async () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [1000, 600],
    });
    doc.autoTable({
      html: "#my-table",
    });
    doc.save(`${modifiedString}.pdf`);
  };

  // const exportXLS = async () => {
  //   var wb = XLSX?.utils?.book_new();
  //   var ws = XLSX?.utils?.json_to_sheet(allData);
  //   XLSX?.utils?.book_append_sheet(wb, ws, "MySheet1");
  //   XLSX?.writeFile(wb, `${modifiedString}.xlsx`);
  // };

  const exportXLS = async () => {
    const flattenObject = (ob) => {
      const toReturn = {};
      
      for (const i in ob) {
        if (!ob.hasOwnProperty(i)) continue;
        
        if ((typeof ob[i]) === 'object' && ob[i] !== null) {
          if (Array.isArray(ob[i])) {
            // Join array elements into a single string
            toReturn[i] = ob[i].join(', ');
          } else {
            const flatObject = flattenObject(ob[i]);
            for (const x in flatObject) {
              if (!flatObject.hasOwnProperty(x)) continue;
              toReturn[i + '.' + x] = flatObject[x];
            }
          }
        } else {
          toReturn[i] = ob[i];
        }
      }
      return toReturn;
    };
  
    const flattenedData = allData?.map(data => flattenObject(data));
  
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(flattenedData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `${modifiedString}.xlsx`);
  };


  console.log("DDDDDDDDDDDDDDD", data);

  useEffect(() => {
    setAllColumnsData(allColumns);
  }, [allColumns]);

  useEffect(() => {
    setFilteredColumns && setFilteredColumns(allColumnsData);
  }, [allColumnsData]);

  const options = otherEvaluatorsData?.map((i, j) => ({
    value: i.agent_id,
    label: i.agent_name_id,
  }));

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setEvaluatorsFunc && setEvaluatorsFunc(selectedOption.value);
    } else {
      setEvaluatorsFunc && setEvaluatorsFunc("");
    }
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;
    if (selectedOption > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedOption} items`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const readMoreDataOption = ["Select", 15, 20, 25, 30]?.map((i, j) => ({
    value: i,
    label: i,
  }));

  const handleReadMore = (option) => {
    if (option.value === 0) {
      setDataCountTable(null); // Clear the selected option if "Select" is chosen
      return;
    }

    setDataCountTable(option); // Set the selected option for other values
    // Perform any additional actions or assignments here
  };

  const defaultValue = readMoreDataOption[0];

  // useEffect(()=>{
  //   searchData&&searchData(data2)
  // },[data2])

  const toggleColumn = (e, columnId) => {
    addRemoveTableValues(e?.target.checked,columnId)
    if (e.target.checked === false) {
      setAllColumnsData((prevData) =>
        prevData.filter((column) => column?.id !== columnId)
      );
    } else {
      setAllColumnsData((prevData) => {
        const existingIndex = prevData.findIndex(
          (column) => column?.id === columnId
        );

        if (existingIndex !== -1) {
          const updatedData = [...prevData];
          updatedData[existingIndex] = allColumns.find(
            (column) => column?.id === columnId
          );
          return updatedData;
        }
        return [
          ...prevData,
          allColumns.find((column) => column?.id === columnId),
        ];
      });
    }
  };

  return (
    <>
      <div className="ip-section">
        <div className="container-fluid">
          <div className="ip-table p-0 data_list_div">
            <nav class="navbar navbar-expand-lg">
              <h3 className="breadcrumb-item active mx-4 mt-2">
                {title ? title : `Call Information`}
              </h3>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item active">
                    {/* <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a> */}
                  </li>
                  <li class="nav-item">
                    {/* <a class="nav-link" href="#">Link</a> */}
                  </li>
                  <li class="nav-item dropdown">
                    {/* <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Dropdown  </a> */}
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                      {/* <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a> */}
                    </div>
                  </li>
                  <li class="nav-item">
                    {/* <a class="nav-link disabled" href="#">Disabled</a> */}
                  </li>
                </ul>
                {userHistoryFilter ? (
                  <div className="user-history-filter my-2">
                    <div className="filter-field">
                      <input
                        type="date"
                        max={toDate}
                        className="form-control"
                        onChange={(e) => setFromDate(e.target.value)}
                        placeholder="From Date"
                        value={fromDate}
                      />
                    </div>
                    <div className="filter-field">
                      <input
                        type="date"
                        min={fromDate}
                            max={today}
                        onChange={(e) => setToDate(e.target.value)}
                        className="form-control"
                        placeholder="To Date"
                        value={toDate}
                      />
                    </div>
                    <div className="filter-field">
                      <Select
                        options={usersList}
                        isDisabled={false}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isClearable={true}
                        components={{
                          Option,
                          ValueContainer,
                        }}
                        onChange={(e) => handleUserListChange(e)}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "100px", // Adjust the border-radius value as per your needs
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                        }}
                        allowSelectAll={true}
                        // isSearchable={true}
                        value={userData?.value}
                        // valueRenderer={this.state.optionSelected.length}
                        className="basic-multi-select login-history-select"
                        classNamePrefix="select"
                      />
                    </div>

                    <div style={{ marginRight: 10 }}>
                      <button
                        onClick={onSubmitFilterReport}
                        type="submit"
                        className={`btn btn-primary beta add_attributes ${isPending ? 'loading' : ''}`}
                      >
                        <span
                          className="las la-search"
                          style={{ fontSize: 14 }}
                        ></span>
                        <span className="loader">
                          <span
                            className="spinner-border"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </span>
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {quickSearch && (
                  <div class="form-inline my-2 my-lg-0">
                    {console.log("callListingcallListing", callListing)}

                    <input // disabled={//   agent_id !== "" && call_id === "" && "disabled"// }
                      type="text"
                      name="call_id"
                      id="quick_search_callid"
                      className={`form-control my-3 mx-2 ${
                        onlyForForms && onlyForForms && "d-none"
                      }`}
                      placeholder="Call Id"
                      autoComplete="off"
                      onInput={(e) => [
                        e.target.value !== ""
                          ? [
                              setCallId1(e.target.value),
                              setIsSearchBlank && setIsSearchBlank(false),
                            ]
                          : setIsSearchBlank && setIsSearchBlank(true),
                        callId(e.target.value),
                      ]}
                    />
                    <input
                      // disabled={
                      //   agent_id === "" && call_id !== "" && "disabled"
                      // }
                      type="text"
                      name="agent_id"
                      id="quick_search_agentid"
                      className={`form-control ${
                        onlyForForms && onlyForForms && "d-none"
                      }`}
                      placeholder="Agent Id"
                      autoComplete="off"
                      onInput={(e) => [
                        e.target.value !== ""
                          ? [
                              setAgentId1(e.target.value),
                              setIsSearchBlank && setIsSearchBlank(false),
                            ]
                          : setIsSearchBlank && setIsSearchBlank(true),
                        agentId(e.target.value),
                      ]}
                    />
                    <button
                      onClick={() => [searchData(),gotoPage(0)]}
                      className={`btn btn-outline-primary btn-md float-right mx-2 ${
                        onlyForForms && onlyForForms && "d-none"
                      }`}
                      id="quick_search"
                    >
                      <span>Search</span>
                      <span className="loader">
                        <span
                          className="spinner-border"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </span>
                    </button>
                    {showEval ? (
                      <>
                        <Select
                          className="mx-2"
                          options={options}
                          placeholder={`${
                            avalAudit
                              ? "Please Select Auditor"
                              : "Select Evaluator"
                          }`}
                          isClearable
                          isSearchable // Add the isSearchable prop to enable the search box
                          onChange={handleSelectChange}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px", // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }), // Hide the separator line
                          }}
                        />
                        <button
                          type="button"
                          onClick={setAssignCalls}
                          className={`btn btn-md btn-outline-primary ${
                            isDataFound ? "loading" : ""
                          }`}
                        >
                          <span>Assign</span>
                          <span class="loader">
                            <span
                              class="spinner-border"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </span>
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                 {universalReprocess && (
                  <div className="mr-2">
                    <button
                      id="create_training"
                      onClick={()=>setReprocessAppModal(true)}
                      className="btn btn-outline-primary "
                      data-toggle="modal"
                      // data-target="#application-modal"
                    >
                      <span>Universal Form Reprocess</span>
                    </button>
                  </div>
                )}
                {SS_CreateApp && (
                  <div className="mr-2">
                    <button
                      id="create_app"
                      onClick={() => [SS_CreateApp(), openAppModal("create")]}
                      className="btn btn-outline-primary "
                      data-toggle="modal"
                      // data-target="#application-modal"
                    >
                      <span>Create Application</span>
                    </button>
                  </div>
                )}
                {SS_CreateTag && (
                  <div className="mr-2">
                    <button
                      id="create_tag"
                      onClick={() => [SS_CreateTag(), openTagModal("create")]}
                      className="btn btn-outline-primary "
                      data-toggle="modal"
                      // data-target="#application-modal"
                    >
                      <span>Create Tag</span>
                    </button>
                  </div>
                )}

                {isFolderCreation && (
                  <div className="mr-2 btn-sm">
                    <button
                      //id='create_app'
                      style={{ fontSize: "12px" }}
                      onClick={() => setModalBool && setModalBool(true)}
                      className="btn btn-outline-primary "
                      data-toggle="modal"
                      data-target="#create_folder_modal"
                    >
                      <span>Create Folder</span>
                    </button>
                  </div>
                )}
                {totalExcelExport1 && (
                  <div className="mr-2">
                    <button
                      type="button"
                      // id='create_tag'
                      onClick={handleDownloadXLSX1}
                      className="btn btn-outline-primary "
                      // data-toggle="modal"
                      // data-target="#application-modal"
                    >
                      <span>Export As Excel</span>
                    </button>
                  </div>
                )}
                {totalExcelExport2 && (
                  <div className="mr-2">
                    <button
                      type="button"
                      // id='create_tag'
                      onClick={handleDownloadXLSX2}
                      className="btn btn-outline-primary "
                      // data-toggle="modal"
                      // data-target="#application-modal"
                    >
                      <span>Export As Excel</span>
                    </button>
                  </div>
                )}
                {totalExcelExport1 && (
                  <div className="mr-2">
                    <button
                      type="button"
                      // id='create_tag'
                      onClick={handleDownloadPDF1}
                      className="btn btn-outline-primary "
                      // data-toggle="modal"
                      // data-target="#application-modal"
                    >
                      <span>Export As PDF</span>
                    </button>
                  </div>
                )}
                {totalExcelExport2 && (
                  <div className="mr-2">
                    <button
                      type="button"
                      // id='create_tag'
                      onClick={handleDownloadPDF2}
                      className="btn btn-outline-primary "
                      // data-toggle="modal"
                      // data-target="#application-modal"
                    >
                      <span>Export As PDF</span>
                    </button>
                  </div>
                )}
                <div class="dropdown">
                  <button
                    class="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Export
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu export-menu">
                    <li>
                      {isCSVReport && (
                        <div className="table-header-group d-inline-flex align-items-center">
                          <div className="export_btns">
                            <div className="dt-buttons ">
                              <CSVLink {...csvReport}>
                                <button
                                  className="dt-button buttons-excel buttons-html5 csv-button"
                                  tabIndex="0"
                                  aria-controls="template_data"
                                  type="button"
                                >
                                  <span>CSV</span>
                                </button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                    <li>
                      {isPDFReport && (
                        <div className="table-header-group d-inline-flex align-items-center">
                          <div className="export_btns">
                            <div className="dt-buttons">
                              <button
                                className="dt-button buttons-excel buttons-html5 pdf-button"
                                tabIndex="0"
                                aria-controls="template_data"
                                type="button"
                                onClick={exportPDF}
                              >
                                <span>PDF</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                    <li>
                      {isXLSReport && (
                        <div className="table-header-group d-inline-flex align-items-center">
                          <div className="export_btns">
                            <div className="dt-buttons">
                              <button
                                className="dt-button buttons-excel buttons-html5 xls-button"
                                tabIndex="0"
                                aria-controls="template_data"
                                type="button"
                                onClick={exportXLS}
                              >
                                <span>.XLS</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
                <div>
                  <input
                    type="text"
                    className="form-control my-2 mx-2"
                    placeholder={`Search: ${
                      totalRec > 1
                        ? totalRec + " records..."
                        : totalRec + " record..."
                    }`}
                    onChange={(e) => [
                      setSearchValue && setSearchValue(e.target.value),
                      setSearchUniversalValue &&
                        setSearchUniversalValue(e.target.value),
                    ]}
                  />
                </div>
                {agentHistory && (
                  <>
                    <div>
                      <input
                        className="form-control mx-3"
                        type="text"
                        required=""
                        autoComplete="off"
                        placeholder="Agent ID"
                        name="agentIdNew"
                        onInput={(e) =>
                          setAgentIdNew && setAgentIdNew(e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        id="save_btn"
                        className={`btn btn-primary mx-4`}
                        onClick={handleHistoryAgent && handleHistoryAgent}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
                <span className="col-actions col-actions-data">
                  <span className="dropdown">
                  {
                      allColumns[0]?.Header!=="No Data" &&
                    <button
                      className="dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="las la-ellipsis-h"></i>
                    </button>
                  }

                    <div className="dropdown-menu datavisibilty-scroll dropdown-menu-right primary">
                      {allColumns.map((column) => (
                        <span key={column.id} class="dropdown-item">
                          <div onClick={(e) => [toggleColumn(e, column.id)]}>
                            <input
                              type="checkbox"
                              //onChange={() => toggleColumn(column.id)}
                              // checked={checkedColumns?.includes(column.id)}
                              {...column.getToggleHiddenProps()}
                            />

                            {column.id}
                          </div>
                        </span>
                      ))}
                    </div>
                  </span>
                </span>
              </div>
            </nav>

            <nav class="navbar navbar-expand-lg" style={{ marginTop: "-25px" }}>
              {/* <span className='mx-4 d-flex'><p className='my-3'>Show</p> 
  <Select defaultValue={defaultValue} onChange={handleReadMore} value={dataCountTable} options={readMoreDataOption} className='mx-3'/> <p className='my-3'>entries</p></span> */}

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item active">
                    {/* <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a> */}
                  </li>
                  <li class="nav-item">
                    {/* <a class="nav-link" href="#">Link</a> */}
                  </li>
                  <li class="nav-item dropdown">
                    {/* <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a> */}
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                      {/* <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a> */}
                    </div>
                  </li>
                  <li class="nav-item">
                    {/* <a class="nav-link disabled" href="#">Disabled</a> */}
                  </li>
                </ul>

                {evaluatorSearch && (
                  <div class="form-inline my-2 my-lg-0">
                    <div className="table-header-group d-inline-flex align-items-center">
                      {/* <GlobalFilter /> */}
                      <div className="table-header-control mr-2">
                        {/* <select
                          className="form-control"
                          onChange={(e) => setEvaluatorsFunc(e.target.value)}
                        >
                          <option value="">Select Evaluator</option>
                          {otherEvaluatorsData !== undefined &&
                            otherEvaluatorsData.map((i, j) => {
                              return (
                                <option value={i.agent_id}>
                                  {i.agent_name_id}
                                </option>
                              );
                            })}
                        </select> */}

                        {/* <Select

                          options={options}
                          placeholder="Select Evaluator"
                          isClearable
                          isSearchable  // Add the isSearchable prop to enable the search box
                          onChange={handleSelectChange}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: '100px' // Adjust the border-radius value as per your needs
                            }),
                            indicatorSeparator: (provided) => ({ ...provided, display: 'none' }), // Hide the separator line

                          }}
                        /> */}
                      </div>
                      {/* <button
                        type="button"
                        onClick={setAssignCalls}
                        className={`btn btn-md btn-outline-primary ${isDataFound ? "loading" : ""
                          }`}
                      >
                        <span>Submit</span>
                        <span class="loader">
                          <span
                            class="spinner-border"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </span>
                      </button> */}
                    </div>
                  </div>
                )}
              </div>
            </nav>

            {/* {title && (
              <div className='table-title-head'>
                <span className='table-heading'>{title}</span>
              </div>
            )} */}
            <div className="table-header ">
              <div
                className={`d-flex flex-wrap align-items-center ${
                  isTrainingListHeaderButtons ||
                  isCategoryListHeaderButtons ||
                  isLessonsListHeaderButtons ||
                  isTestsListHeaderButtons
                    ? "justify-content-end"
                    : "justify-content-between"
                }`}
              >
                {/* <div className="d-flex align-items-center">
                  {isCSVReport && (
                    <div className="table-header-group d-inline-flex align-items-center">
                      <div className="export_btns">
                        <div className="dt-buttons">
                          <CSVLink {...csvReport}>
                            <button
                              className="dt-button buttons-excel buttons-html5"
                              tabIndex="0"
                              aria-controls="template_data"
                              type="button"
                            >
                              <span>Export</span>
                            </button>
                          </CSVLink>
                        </div>
                      </div>
                    </div>
                  )}

                  {isAppFilter && (
                    <div className="table-header-group d-inline-flex align-items-center mr-2 ml-4">
                      <div className="table-header-control mr-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Applications"
                          onInput={(e) => SSGetAppName(e.target.value)}
                        />
                      </div>
                      <button
                        onClick={SS_SearchApp}
                        className="btn btn-outline-primary btn-md"
                      >
                        <span>Search</span>
                        <span className="loader">
                          <span
                            className="spinner-border"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </span>
                      </button>
                      {SS_CreateApp && (
                        <button
                          id='create_app'
                          onClick={() => [SS_CreateApp(), openAppModal('create')]}
                          className="btn btn-outline-primary btn-md ml-2"
                          data-toggle="modal"
                        // data-target="#application-modal"
                        >
                          <span>Create</span>
                        </button>
                      )}
                    </div>

                  )}
                </div> */}

                {isAddAlert && (
                  <div class="table-controls d-flex">
                    <button
                      onClick={() => [
                        saveAlertForm(""),
                        setEditDetails && setEditDetails([]),
                        setIsEdit && setIsEdit(false),
                      ]}
                      class="btn btn-md btn-outline-primary btnAddAlert"
                    >
                      Add Alert
                    </button>
                    <div class="export_btns">
                      <div class="dt-buttons">
                        <button
                          class="dt-button buttons-excel buttons-html5"
                          tabindex="0"
                          aria-controls="summary_table"
                          type="button"
                        >
                          <span>Export</span>
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                )}
                {isQAAutoFilter && (
                  <div className="d-flex align-items-center">
                    <div className="d-inline-flex align-items-center mr-2">
                      <div className="table-header-control mr-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Agent Id"
                        />
                      </div>
                      <button
                        className="btn btn-outline-primary btn-md"
                        id="quick_search"
                      >
                        <span>Search</span>
                        <span className="loader">
                          <span
                            className="spinner-border"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </span>
                      </button>
                      <div className="export_btns mr-2">
                        <div className="dt-buttons">
                          <button
                            className="dt-button buttons-excel buttons-html5 ml-2"
                            type="button"
                          >
                            <span>Export</span>
                          </button>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* {(isSetting && displayCharts === 'applicaiton') && <div className="d-flex align-items-center">
                  <div className="d-inline-flex align-items-center mr-2">
                    <button
                      type="button"
                      className="btn btn-circle-primary"
                      data-toggle="modal"
                      data-target="#application-setting"
                    >
                      <i className="las la-cog"></i>{" "}
                    </button>
                  </div>
                </div>} */}

                {/* <div className="d-inline-flex align-items-center">
                  <GlobalFilter
                    globalFilter={state.globalFilter}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={setGlobalFilter}
                  />
                  <span className="col-actions col-actions-data">
                    <span className="dropdown">
                      <button
                        // className="dropdown-toggle"
                        type="button"
                        className="btn btn-circle-primary"
                        data-toggle="modal"
                        data-target="#application-setting"
                      >
                        <i className="las la-cog"></i>{" "}
                      </button>
                      </span>
                    </span> */}

                {!isTrainingListHeaderButtons &&
                  !isCategoryListHeaderButtons &&
                  !isLessonsListHeaderButtons &&
                  !isTestsListHeaderButtons && (
                    <div className="d-inline-flex align-items-center  ml-auto">
                      <GlobalFilter
                        globalFilter={state.globalFilter}
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        setGlobalFilter={setGlobalFilter}
                      />

                      {/* <input type='text' className='form-control my-2 mx-2' placeholder={`Search: ${totalRec > 1 ? totalRec + " records..." : totalRec + " record..."}`} onChange={(e) => [setSearchValue && setSearchValue(e.target.value), setSearchUniversalValue && setSearchUniversalValue(e.target.value)]} /> */}
                      {/* <span className="col-actions col-actions-data">
                        <span className="dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="las la-ellipsis-h"></i>
                          </button>
                          <div className="dropdown-menu datavisibilty-scroll dropdown-menu-right primary">
                            {allColumns.map((column) => (

                              <span key={column.id} class="dropdown-item">
                                <div onClick={(e) => [toggleColumn(e, column.id)]}>
                                  <input
                                    type="checkbox"
                                    //onChange={() => toggleColumn(column.id)}
                                    // checked={checkedColumns?.includes(column.id)}
                                    {...column.getToggleHiddenProps()}

                                  />

                                  {column.id}
                                </div>

                              </span>
                            ))}
                          </div>
                        </span>
                      </span> */}
                    </div>
                  )}
                {isTrainingListHeaderButtons && (
                  <>
                    <div>
                      <CSVLink {...training_csvReport}>
                        <button
                          type="button"
                          class="btn btn-outline-primary btn-md mx-1"
                        >
                          CSV
                        </button>
                      </CSVLink>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-md mx-1"
                      >
                        PDF
                      </button>
                      {role !== "QS" && role !== "QE" && (
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#add-training-modal"
                          className="btn btn-outline-primary btn-md mx-1"
                        >
                          Add New
                        </a>
                      )}
                    </div>
                    <div className="d-inline-flex align-items-center">
                      <GlobalFilter
                        globalFilter={state.globalFilter}
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        setGlobalFilter={setGlobalFilter}
                      />
                      <span className="col-actions col-actions-data">
                        <span className="dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="las la-ellipsis-h"></i>
                          </button>
                          <div className="dropdown-menu datavisibilty-scroll dropdown-menu-right primary">
                            {allColumns.map((column) => (
                              <span key={column.id} class="dropdown-item">
                                <input
                                  type="checkbox"
                                  {...column.getToggleHiddenProps()}
                                />
                                {column.id}
                              </span>
                            ))}
                          </div>
                        </span>
                      </span>
                    </div>
                  </>
                )}
                {isCategoryListHeaderButtons && (
                  <>
                    <div>
                      <CSVLink {...category_csvReport}>
                        <button
                          type="button"
                          class={`btn btn-outline-primary btn-md mx-1 ${
                            onlyForForms && onlyForForms && "d-none"
                          }`}
                        >
                          CSV
                        </button>
                      </CSVLink>
                      <button
                        type="button"
                        class={`btn btn-outline-primary btn-md mx-1 ${
                          onlyForForms && onlyForForms && "d-none"
                        }`}
                      >
                        PDF
                      </button>
                      <button
                        type="button"
                        class={`btn btn-outline-primary btn-md mx-1 ${
                          onlyForForms && onlyForForms && "d-none"
                        }`}
                      >
                        .XLS
                      </button>
                      {role !== "QS" && role !== "QE" && (
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#add-category-modal"
                        >
                          Add New
                        </a>
                      )}
                    </div>
                    <div className="d-inline-flex align-items-center">
                      <GlobalFilter
                        globalFilter={state.globalFilter}
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        setGlobalFilter={setGlobalFilter}
                      />
                      <span className="col-actions col-actions-data">
                        <span className="dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="las la-ellipsis-h"></i>
                          </button>
                          <div className="dropdown-menu datavisibilty-scroll dropdown-menu-right primary">
                            {allColumns.map((column) => (
                              <span key={column.id} class="dropdown-item">
                                <input
                                  type="checkbox"
                                  {...column.getToggleHiddenProps()}
                                />
                                {column.id}
                              </span>
                            ))}
                          </div>
                        </span>
                      </span>
                    </div>
                  </>
                )}
                {isLessonsListHeaderButtons && (
                  <>
                    <div>
                      <CSVLink {...lessons_csvReport}>
                        <button
                          type="button"
                          class="btn btn-outline-primary btn-md mx-1"
                        >
                          CSV
                        </button>
                      </CSVLink>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-md mx-1"
                      >
                        PDF
                      </button>
                      {role !== "QS" && role !== "QE" && (
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#add-lesson-modal"
                          className="btn btn-outline-primary btn-md mx-1"
                        >
                          Add New
                        </a>
                      )}
                    </div>
                    <div className="d-inline-flex align-items-center">
                      <GlobalFilter
                        globalFilter={state.globalFilter}
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        setGlobalFilter={setGlobalFilter}
                      />
                      <span className="col-actions col-actions-data">
                        <span className="dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="las la-ellipsis-h"></i>
                          </button>
                          <div className="dropdown-menu datavisibilty-scroll dropdown-menu-right primary">
                            {allColumns.map((column) => (
                              <span key={column.id} class="dropdown-item">
                                <input
                                  type="checkbox"
                                  {...column.getToggleHiddenProps()}
                                />
                                {column.id}
                              </span>
                            ))}
                          </div>
                        </span>
                      </span>
                    </div>
                  </>
                )}
                {isTestsListHeaderButtons && (
                  <>
                    <div>
                      <CSVLink {...test_csvReport}>
                        <button
                          type="button"
                          class="btn btn-outline-primary btn-md mx-1"
                        >
                          CSV
                        </button>
                      </CSVLink>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-md mx-1"
                      >
                        PDF
                      </button>
                      {role !== "QS" && role !== "QE" && (
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#add-test-modal"
                          className="btn btn-outline-primary btn-md mx-1"
                        >
                          Add New
                        </a>
                      )}
                    </div>
                    <div className="d-inline-flex align-items-center">
                      <GlobalFilter
                        globalFilter={state.globalFilter}
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        setGlobalFilter={setGlobalFilter}
                      />
                      <span className="col-actions col-actions-data">
                        <span className="dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="las la-ellipsis-h"></i>
                          </button>
                          <div className="dropdown-menu datavisibilty-scroll dropdown-menu-right primary">
                            {allColumns.map((column) => (
                              <span key={column.id} class="dropdown-item">
                                <input
                                  type="checkbox"
                                  {...column.getToggleHiddenProps()}
                                />
                                {column.id}
                              </span>
                            ))}
                          </div>
                        </span>
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>

            <Styles>
              {/* {getAllColumns(allColumns)} */}

              {
                <BTable
                  style={{ border: "none" }}
                  responsive
                  thead-primary
                  id="my-table"
                  hover
                  size="sm"
                  {...getTableProps()}
                >
                  <thead style={{}}>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="text-nowrap"
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            style={{
                              // borderBottom: 'solid 3px #0d9dd9',
                              background: "#0d9dd9",
                              color: "#fff",
                              fontWeight: "bold",
                              padding: "1.2rem 1.5rem",
                              borderRight: "none",
                              verticalAlign: "middle",
                            }}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody className="text-nowrap" {...getTableBodyProps()}>
                    {page.length && !isPending ? (
                      page.map((row) => {
                        lastRecId = row.id;
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  style={{
                                    padding: "0.8rem 1.5rem",
                                    borderRight: "none",
                                    borderBottom: "1px solid #dee2e6",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    ) : isPending ? (
                      <tr>
                        {console.log("isPending", isPending)}
                        <td
                          colSpan={headerGroups[0].headers.length}
                          className="text-center p-4"
                        >
                          <span className="loader">
                            <span
                              className="spinner-border"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <br />
                            {/* <div class="spinner-border" role="status">
                            <span class="sr-only">Data is loading.Please Wait...</span>
                            </div> */}
                          </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          colSpan={headerGroups[0].headers.length}
                          className="text-center p-4"
                        >
                          No Data Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </BTable>
              }

              <div className="pagination mt-4 d-flex flex-wrap align-items-center justify-content-between pb-4">
                <span>
                  Showing{" "}
                  {totalRec === 0 ? (
                    <>
                      <strong>
                        {pageIndex === 0 ? pageIndex : pageIndex + "0"} to{" "}
                        {/* {(lastRecId !== null ? parseInt(lastRecId) : -1) + 1} of {totalRec} */}
                        {"0"} of {totalRec}{" "}
                      </strong>
                      <span>entries</span>
                    </>
                  ) : (
                    <>
                      <strong>
                        {pageIndex === 0 ? pageIndex + 1 : pageIndex + "1"} to{" "}
                        {(lastRecId !== null ? parseInt(lastRecId) : -1) + 1} of{" "}
                        {totalRec}{" "}
                      </strong>
                      <span>entries</span>
                      <br />

                      {/* {Array.from(Array(totalPages), (e, i) => {

                          const pageNumber = i + 1;
                          if (totalPages > 10 && Math.abs(i - pageIndex) > 2 && i !== 0 && i !== totalPages - 1) {
                            // Display ellipsis for large number of pages
                            if (Math.abs(i - pageIndex) === 3) {
                              return <span key={pageNumber}>...</span>;
                            }
                            return null;
                          }
                          return (
                            <button
                              key={pageNumber}
                              onClick={() => gotoPage(i)}
                              style={{
                                fontWeight: pageIndex === i ? "bold" : "normal",

                              }}
                            >
                              <span style={{ fontSize: "13px", letterSpacing: '2px' }}>{pageNumber}</span>
                            </button>
                          );
                        }
                        )
                          } */}
                    </>
                  )}{" "}
                </span>
                <div>
                  <button
                    className="mr-2"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    {"Previous"}
                  </button>{" "}
                  <button
                    style={
                      2 <= pageIndex + 1 ? { margin: 10 } : { display: "none" }
                    }
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    {pageIndex}
                  </button>
                  <strong className="pagination-count">{pageIndex + 1}</strong>
                  <button
                    style={
                      Math.ceil(totalRec / 10) > pageIndex + 1
                        ? { margin: 10 }
                        : { display: "none" }
                    }
                    onClick={() => gotoPage(pageIndex + 1)}
                    disabled={!canNextPage}
                  >
                    {pageIndex + 2}
                  </button>
                  <button
                    style={
                      Math.ceil(totalRec / 10) > pageIndex + 2
                        ? { margin: 10 }
                        : { display: "none" }
                    }
                    onClick={() => gotoPage(pageIndex + 2)}
                    disabled={!canNextPage}
                  >
                    {pageIndex + 3}
                  </button>
                  <button
                    style={
                      Math.ceil(totalRec / 10) > pageIndex + 3
                        ? { margin: 10 }
                        : { display: "none" }
                    }
                    onClick={() => gotoPage(pageIndex + 3)}
                    disabled={!canNextPage}
                  >
                    {pageIndex + 4}
                  </button>
                  <button
                    className="ml-2"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    {"Next"}
                  </button>{" "}
                </div>
              </div>
            </Styles>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataTableView;
