import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { ErrorNotify } from "../../Toast";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const RecruiterAttributeScore = ({
  fromDate,
  toDate,
  dateRange,
  latestForm,
  formValVersion,
  fromDateForDash,
  setAttributeScoretData,
  attributeScoretData,
  toDateForDash,
  dateTypeForDash,
  filterStatus
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [visibleLegends, setVisibleLegends] = useState({
    Good: true,
    Bad: true,
    Neutral: true,
    Positive: true,
  });

  useEffect(() => {
    if(latestForm?.label!==undefined&&latestForm?.value?.split("||")[0]!==undefined){
    if (
      typeof formValVersion !== "string" &&
      formValVersion?.form_name &&
      formValVersion?.form_version
    ) {
      // const url = `${base_url}dashboard-ai/call-verdict`;
      const url = `${base_url}dashboard-ai/overall-call-attributes`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          start_date: fromDateForDash,
          end_date: toDateForDash,
          daterange: dateRange,
          date_type: dateTypeForDash,
          form:
            process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "recruitment"
              ?  [latestForm?.value]
              : [`${formValVersion?.form_name}||${formValVersion?.form_version}`],
          proxy_user_id: localStorage.getItem("settedProxy")
            ? JSON.parse(localStorage.getItem("settedProxy"))?.value
            : "",
          proxy_by: localStorage.getItem("settedProxy")
            ? localStorage.getItem("loginUserUniqueid")
            : "",
        }),
      };
      setIsLoading(true);
      setAttributeScoretData([]);
      setChartData(null);
      callApi(url, requestOptions, "recruiter-attribute-score");
    }
  }
  }, [dateRange, formValVersion,filterStatus]);

  const callApi = async (url, requestOptions, flag) => {
    try {
      const res = await fetch(url, requestOptions);
      const data = await res.json();

      if (flag === "recruiter-attribute-score") {
        if (data.status === 200) {
          const filteredData = data.data.filter(agent => 
            agent.attribute.some(attr => attr.avg_score !== "0%")
        );
        console.log("filteredDatafilteredData",filteredData);
        
          setAttributeScoretData(filteredData);
          setIsLoading(false);
        } else {
          handleApiError(data);
        }
      }
    } catch (err) {
      setIsLoading(false);
      ErrorNotify("Something went wrong. Please contact the Administrator!", {
        icon: "error",
      });
    }
  };

  const handleApiError = (data) => {
    if (data?.status === 401) {
      localStorage.clear();
    }
    setIsLoading(false);
    ErrorNotify(data.message, { icon: "error" });
  };

  const toggleLegend = (legendName) => {
    setVisibleLegends((prevState) => ({
      ...prevState,
      [legendName]: !prevState[legendName],
    }));
  };

  useEffect(() => {
    if (Array.isArray(attributeScoretData) && attributeScoretData.length > 0) {
      const categories = attributeScoretData?.map((agent) => ({
        label: agent.agent_name,
      }));

      

     console.log("categoriescategoriescategories",categories);
     

      const callVerdictTypes = ["Good", "Bad", "Neutral", "Positive"];
      const colors = ["#4BC0C0", "#FF6384", "#C9CBCE", "#FFCE56","#AB96FF"];

      const dataset = attributeScoretData?.[0]?.attribute?.map((attr, index) => ({
        seriesname: attr.attr_name, // Set attribute name as the series name
        color: colors[index % colors.length], // Cycle through colors
        data: attributeScoretData.map((agent) => {
          const rawValue = parseFloat(
            agent.attribute.find((a) => a.attr_name === attr.attr_name)?.avg_score
          ) || 0;
      
          const value = Math.min(rawValue, 100); // Cap value at 100
      
          return {
            label: agent.agent_name, // Set agent name as label
            value, // Numeric value for the bar height
            displayValue: value === 0 ? "" : `${value}%`, // Manually add % sign, hide for 0
            showValue: value === 0 ? "0" : "1", // Hide value label if it's 0
          };
        }),
      }));
      
      
      
      

      setChartData({
        chart: {
          caption: "Recruiter Attribute Score",
          yAxisName: "Score (%)",
          theme: "fusion",
          // yAxisMinValue: "0",
          // yAxisMaxValue: "100",
          showYAxisValues: "0",
          valueSuffix: "%",
          plotToolText: "<b>$seriesName</b>: $dataValue%",

          showValues: "1",
          scrollHeight: "15",
          showHoverEffect: "1",
          
        },
        categories: [
          {
            category: categories,
          },
        ],
        dataset: dataset,
      });
      
    }
    
  }, [attributeScoretData, visibleLegends]);

  return (
    <div id="zoom_chart5" className="chart-card p-4">
      <div className="chart-header">
        {!chartData && (
          <h4 style={{ textAlign: "center" }}>Recruiter Attribute Score</h4>
        )}
      </div>

      {/* Custom Legend */}
     

      {isLoading ? (
        <div
          id="custom4_p"
          className="chart_contents"
          style={{
            height: "250px",
            textAlign: "center",
            position: "absolute",
            left: "0",
            right: "0",
            bottom: "0",
            top: "50%",
          }}
        >
          <div
            className={isLoading ? "loading" : ""}
            id="passrateid"
            style={{ height: "200px" }}
          >
            <span className="loader">
              <span className="spinner-border" role="status" aria-hidden="true"></span>
            </span>
          </div>
        </div>
      ) : chartData ? (
        <ReactFC
          type="scrollstackedcolumn2d"
          width="100%"
          height="400"
          dataFormat="json"
          dataSource={chartData}
        />
      ) : (
        <div
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          No Data Available
        </div>
      )}
    </div>
  );
};

export default RecruiterAttributeScore;
