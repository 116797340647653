import React, { useEffect, useState } from "react";
import Select from "react-select";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";
import { ErrorNotify } from "../../Toast";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, Widgets);

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
let options = [];

// Function to generate random light colors
const predefinedColors = [
  "#A6DE88", "#CDC1FF", "#FFB370", "#D3EE98", "#FFF3BF",
  "#DCE2D4", "#E3C5C6", "#00FFFF", "#FAA0A0", "#86CAEA"
];

const getRandomLightColor = (() => {
  let index = 0;
  return () => {
    const color = predefinedColors[index % predefinedColors.length];
    index++;
    return color;
  };
})();


const CategoryPerformanceChart = ({
  fromDate,
  toDate,
  dateRange,
  catPerformanceData,
  setCatPerformanceData,
  formValVersion,
  fromDateForDash,toDateForDash,dateTypeForDash,latestForm,
  setCategoryId,
  filterStatus,
  setFilterStatus
  
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);
  const [category, setCategory] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
   
    if(latestForm?.label!==undefined&&latestForm?.value?.split("||")[0]!==undefined){
    if (latestForm && latestForm?.value) {
      const url = `${base_url}dashboard/category-performance`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("loginToken")}`,
        },
        body: JSON.stringify({
         'start_date': fromDateForDash,
                'end_date': toDateForDash,
                'daterange': dateRange,
                'date_type':dateTypeForDash,
         'form':process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "recruitment" ?[latestForm?.value]:[`${formValVersion?.form_name}||${formValVersion?.form_version}`],
          proxy_user_id: localStorage.getItem("settedProxy")
            ? JSON.parse(localStorage.getItem("settedProxy"))?.value
            : "",
          proxy_by: localStorage.getItem("settedProxy")
            ? localStorage.getItem("loginUserUniqueid")
            : "",
        }),
      };
      setIsLoading(true)
      fetchCategoryPerformanceData(url, requestOptions);
    }
  }
  }, [dateRange, formValVersion,filterStatus]);

  useEffect(()=>{
    console.log("filterStatusfilterStatus",filterStatus)
    
  },[filterStatus])

  const fetchCategoryPerformanceData = async (url, requestOptions) => {
    
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      if (data.status === 200) {
        setIsLoading(false);
        options = data.data.map((item) => ({
          value: item.avg_score,
          label: item.cat_name,
        }));

        setCatPerformanceData(
          data.data.map((item) => ({
            label: item.cat_name,
            value: item.avg_score.toString(),
            color: getRandomLightColor(), // Assigning random light color
            id:item?.cat_id
          }))
        );

        
        setChartData(
          data.data.map((item) => ({
            label: item.cat_name,
            value: item.avg_score.toString(),
            color: getRandomLightColor(), // Assigning random light color
            id:item?.cat_id
          }))
        );
        
       
      } else {
        handleError(data);
      }
    } catch (error) {
      handleError();
    }
  };

  const handleError = (data) => {
    setIsLoading(false);
    if (data?.status === 401) {
      localStorage.clear();
    }
    ErrorNotify(
      data?.message || "Something went wrong. Please contact the Administrator!",
      { icon: "error" }
    );
  };

  const handleCatPerformance = (data) => {
    setCategory(data);
  };
  const handleBarClick = (dataObj) => {
    const categoryId = dataObj.id; // Extract category label (or ID if available)
    console.log("Clicked Category ID:", categoryId);
    setCategoryId(categoryId)
    // You can call a function or update state here
  };

  const chartConfigs = {
    type: "scrollColumn2D", // ✅ Enable scrolling bars
    width: "100%",
    height: "320", 
    dataFormat: "json",
    dataSource: {
      chart: {
        xAxisName: `<b>Categories</b>`,
        yAxisName: "Average Score (%)",
        numberSuffix: "%",
        theme: "fusion",
        plotToolText: "<b>$label</b>: $value%",
        
        yAxisMinValue: "0",   
        yAxisMaxValue: "100", 
        yAxisValuesStep: "20",
        
        showYAxisValues: "1",
        showXAxisLine: "1",
        showYAxisLine: "0",
        divLineAlpha: "0",
        showDivLineValues: "1",
        showPlotBorder: "0",
        // labelDisplay: "rotate",
        //  slantLabel: "1",

        // ✅ Scroll Properties
        scrollheight: "10", 
        numVisiblePlot: "5", 
        flatScrollBars: "1",
        scrollShowButtons: "1", 
        scrollToEnd: "0",
        canvasWidth: catPerformanceData.length * 80, // ✅ Ensure enough width for scrolling
      },
      categories: [
        {
          category:catPerformanceData.map((item) => ({
            // label: item.label?.split(" ")?.length>1?item.label?.split(" ")[0]+"...":item.label,
            label:item?.label
            
          }))
        }
      ],
      dataset: [
        {
          data: catPerformanceData.map((item) => ({
            value: item.value,
            toolText: `<b>${item.label}</b>: ${item.value}%`,
            color: item.color,
            id:item?.id
            
          }))
        }
      ]

      // data: chartData.map((item) => ({
      //   label: item.label,
      //   value: item.value,
      //   color: item.color,
      //   toolText: `<b>${item.label}</b>: ${item.value}%`
      // })),
    },
    events: {
      rendered: function () {
        // Wait for chart to fully render
        setTimeout(() => {
          const svgBars = document.querySelectorAll('rect');
    
          if (svgBars.length > 0) {
            svgBars.forEach((bar) => {
              const barHeight = parseFloat(bar.getAttribute("height"));
              const barWidth = parseFloat(bar.getAttribute("width"));
    
              // Apply rounded corners only to the top-left and top-right
              bar.style.clipPath = `inset(0 0 0 0 round 5px 5px 0 0)`;
    
              // Optional fallback if clip-path isn't supported
              bar.setAttribute("rx", "5"); // This still applies rounded edges but on all corners
              bar.setAttribute("ry", "5");
            });
          } else {
            console.warn("Bars not found in the chart DOM.");
          }
        }, 500); // Delay to ensure DOM is fully loaded
      },
      dataPlotClick: function (eventObj, dataObj) {
        handleBarClick(dataObj);
      },
    },
  };

console.log("chartDatachartData",chartData);


  return (
    <>
      {isLoading ? (
        <div className="col-sm-6 col-md-6 col-lg-6">
          <div id="zoom_chart3" className="chart-card p-4" style={{ height: "365px" }}>
            <h4>Category Performance</h4>
            <div className="chart_contents"
            
            style={{
              textAlign: 'center',
              position: 'absolute',
              left: '0',
              right: '0',
              bottom: '0',
              top: '50%'
          }}>
              <span className="loading">
                <span className="spinner-border" role="status" aria-hidden="true"></span>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] !== "recruitment"
              ? "col-sm-6 col-md-6 col-lg-6"
              : "col-sm-6 col-md-6 col-lg-6"
          }
        >
          <div id="zoom_chart3" className="chart-card p-4" style={{ height: "365px" }}>
            <div className="chart-header d-flex justify-content-between align-items-center">
              <h4>Category Performance</h4>
              {/* <Select
                options={options}
                placeholder="Select Category"
                value={category}
                onChange={handleCatPerformance}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "100px",
                    borderColor: "#ccc",
                    "&:hover": { borderColor: "#ccc" },
                  }),
                  indicatorSeparator: () => ({ display: "none" }),
                  option: (provided, state) => ({
                    ...provided,
                    background: state.isSelected ? "#fff" : "transparent",
                    color: state.isSelected ? "#000" : "#333",
                  }),
                }}
              /> */}
            </div>
            <ReactFC key={catPerformanceData.length} {...chartConfigs} />
          </div>
          
        </div>
        
      )}
    </>
  );
};

export default CategoryPerformanceChart;
