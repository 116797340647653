import React from "react"

const auditCount = localStorage.getItem("my_audit_count");
const menuItem = {
    label: `<p>My Audits ${<span class="badge beta">{auditCount}</span>}</p>`,
    url: '/my-audits',
    subMenuChildren: []
  };

export const ModuleAccessData = [

    { label: 'Dashboard', url: '/dashboards', icon: 'lar la-chart-bar', subMenu: [] },
    {
        label: 'KPI Dashboard', icon: '', subMenu: [
            { label: 'Dashboard', url: '/kpi-dashboard', subMenuChildren: [] },
            {
                label: 'Evaluations', url: '', subMenuChildren: [
                    { label: 'Above Goal Details', url: '/evaluations/above-goal-details/:fromList', subMenuChildren: [] },
                    { label: 'Agent Evaluations', url: '/evaluations/agent-evaluation/:fromList', subMenuChildren: [] },
                    { label: 'Auto-Fail Details', url: '/evaluations/auto-fail-details/:fromList', subMenuChildren: [] },
                    { label: 'Below Goal Details', url: '/evaluations/below-goal-details/:fromList', subMenuChildren: [] },
                    { label: 'Coached Details', url: '/evaluations/coached-details/:fromList', subMenuChildren: [] },
                    { label: 'Completed Evaluations', url: '/evaluations/completed-evaluations/:fromList', subMenuChildren: [] },
                    { label: 'My Evaluation Summary', url: '/evaluations/my-evaluation-summery/:fromList', subMenuChildren: [] },
                ]
            },
            {
                label: 'Dispute', url: '', subMenuChildren: [
                    { label: 'Escalation Detail', url: '/dispute/escalation-details/:fromList', subMenuChildren: [] },
                    { label: 'Escalation Status-Closed', url: '/dispute/escalation-status-closed', subMenuChildren: [] },
                    { label: 'Escalation Status-Decline', url: '/dispute/escalation-status-declined', subMenuChildren: [] },
                    { label: 'Escalation Status-Expired', url: '/dispute/escalation-status-expired', subMenuChildren: [] },
                    { label: 'Escalation Status-Open', url: '/dispute/escalation-status-open', subMenuChildren: [] },
                    { label: 'Invalid Escalation', url: '/dispute/invalid-escalation', subMenuChildren: [] },
                    { label: 'Valid Escalation', url: '/dispute/valid-escalation', subMenuChildren: [] },
                ]
            },
            {
                label: 'KPIs', url: '', subMenuChildren: [
                    { label: 'Analysis', url: '/kpis/analysis', subMenuChildren: [] },
                ]
            },
            {
                label: 'Performance Charts', url: '', subMenuChildren: [
                    { label: 'Audit Performance', url: '/performance_charts/audit_performance', subMenuChildren: [] },
                    { label: 'Dispute Performance', url: '/performance_charts/dispute_performance', subMenuChildren: [] },
                    { label: 'Evaluation Performance', url: '/performance_charts/evaluation_performance', subMenuChildren: [] },
                    { label: 'Evaluation Status', url: '/performance_charts/evaluation_status', subMenuChildren: [] },
                    { label: 'LOB Performance', url: '/performance_charts/lob_performance', subMenuChildren: [] },
                    { label: 'Vendor Performance', url: '/performance_charts/vendor_performance', subMenuChildren: [] },
                    { label: 'Location Performance', url: '/performance_charts/location_performance', subMenuChildren: [] },
                    { label: 'Campaign Performance', url: '/performance_charts/campaign_performance', subMenuChildren: [] },
                ]
            },
        ]
    },
    // { label: 'Form Preview', url: '/form-listing', icon: 'las la-bullseye', subMenu: [] },
    { label: 'Forms', url: '',icon: 'las la-bullseye', subMenu:[
        {label:"Form Creation",url:'/form-creation',subMenuChildren:[]},
        {label:"Form Details",url:'/form-details',subMenuChildren:[]},
        {label:"Form Preview",url:'/form-listing',subMenuChildren:[]},
    ]},
    { label: 'Smartspeech Call List', url: '/new-smartspeech-call-list',icon: 'las la-question-circle', subMenu: [] },
    {
        label: 'SmartSpeech', url: '', icon: 'las la-microphone', subMenu: [
            { label: 'Folders', url: '/smartspeech/folders', subMenuChildren: [] },
            { label: 'Applications', url: '/smartspeech-applications', subMenuChildren: [] },
            { label: 'Applications Log', url: '/smartspeech-applications-log', subMenuChildren: [] },
            { label: 'Tags', url: '/smartspeech-tags', subMenuChildren: [] },
            { label: 'Tag Reprocess Log', url: '/ss-tag-reprocess-log', subMenuChildren: [] },
            { label: 'Process Log', url: '/smartspeech-applications-log', subMenuChildren: [] },
            { label: 'Reports', url: '/smartspeech-report', subMenuChildren: [] },

            // { label: 'Call Details', url: '/smartspeech-call-details', subMenuChildren: [] },
            { label: 'Call Search', url: '/smartspeech-call-search', subMenuChildren: [] },
            // { label: 'Call Upload', url: '/smartspeech-call-upload', subMenuChildren: [] },
            // { label: 'Transcribe Files', url: '/smartspeech-transcribe-files', subMenuChildren: [] },
            { label: 'Call List', url: '/smartspeech-call-list', subMenuChildren: [] },
            { label: 'SmartSpeech Call List', url: '/new-smartspeech-call-list', subMenuChildren: [] },
            // { label: 'Dashboard', url: '/smartspeech-dashboard', subMenuChildren: [] },
            { label: 'Dashboard', url: '/smartspeech-dashboard-new', subMenuChildren: [] },
            
            { label: 'Tag Report', url: '/tags-report', subMenuChildren: [] },
            { label: 'QA Automation', url: '/smartspeech-qa-automation', subMenuChildren: [] },
            // { label: 'DFR', url: '/smartspeech-dfr-report', subMenuChildren: [] },

            // { label: 'Call-Distribution Analysis', url: '/smartspeech-dashboard', subMenuChildren: [] },
        ]
    },
    {
        label: 'Call', url: '', icon: 'las la-headset', subMenu: [
            { label: 'Add Call', url: '/add-call', subMenuChildren: [] },
            { label: 'Call List', url: '/call-list', subMenuChildren: [] },
            { label: 'My Call', url: '/my-calls', subMenuChildren: [] },
            { label: 'Un-Assigned', url: '/unassigned-calls', subMenuChildren: [] },
        ]
    },
    
    { label: 'Draft Evaluation', url: '/draft-evaluation', icon: 'lar la-save', subMenu: [] },
    { label: 'Evaluation Summary', url: '/evaluation-summary', icon: 'lar la-list-alt', subMenu: [] },
    // { label: 'Coaching', url: '/feedback', icon: 'las la-comment-dots', subMenu: [] },
    { label: 'Dispute', url: '/dispute', icon: 'las la-exclamation-triangle', subMenu: [] },
    { label: 'Review', url: '/reviewed', icon: 'las la-search', subMenu: [] },
    {
        label: 'ATA', url: '', icon: 'las la-clipboard-check', subMenu: [
            { label: 'Available Audits', url: '/available-audits', subMenuChildren: [] },
            { label: 'My Audits', url: '/my-audits', subMenuChildren: [] },
            { label: 'Audit History', url: '/audit-history', subMenuChildren: [] },
            { label: 'ATA Raw Data', url: '/ata-raw-data', subMenuChildren: [] },
        ]
    },

    {
        label: 'Calibration', url: '', icon: 'las la-users-cog', subMenu: [
            { label: 'Schedule Calibration', url: '/start-calibration', subMenuChildren: [] },
            { label: 'Session List', url: '/schedulelist-calibration', subMenuChildren: [] },
            { label: 'My Calibration', url: '/my-calibration', subMenuChildren: [] },
            { label: 'Review Calibration', url: '/review-calibration', subMenuChildren: [] },
        ]
    },

    { 
        label: 'Reports', icon: 'las la-file-invoice', subMenu: [
            {
                label: 'Evaluation' , url: '', subMenuChildren:[
                    { label: 'Agent Coaching Service Levels', url: '/agent-coaching-service-levels', subMenuChildren: [] },
                    { label: 'Agent Evaluation Summary', url: '/agent-evaluation-summary', subMenuChildren: [] },
                    { label: 'Agent Performance', url: '/agent-performance', subMenuChildren: [] },
                    { label: ' Agent Evaluation Trending ', url: '/agent-evaluation-summary', subMenuChildren: [] },
                    { label: 'Attribute Comments  ', url: '/attribute-comments', subMenuChildren: [] },
                    { label: ' Attribute Trending ', url: '/attribute-trending', subMenuChildren: [] },
                    { label: ' Evaluation Details ', url: 'evaluation-details', subMenuChildren: [] },
                    { label: ' Enterprise Evaluation Trending ', url: '/enterprise-evaluation-trending', subMenuChildren: [] },
                ]
            },
            {
                label: 'Auto-Fail' , url: '', subMenuChildren:[
                    { label: ' Auto-Fail Details ', url: '', subMenuChildren: [] },
                    { label: ' Auto-Fail Report ', url: '', subMenuChildren: [] },
                    { label: ' Auto-Fail Summary ', url: '', subMenuChildren: [] },
                    { label: ' Auto-Fail Trending ', url: '', subMenuChildren: [] },
                ]
            },
            {
                label: 'Coaching' , url: '', subMenuChildren:[
                    { label: ' Coached Report ', url: '', subMenuChildren: [] },
                ]
            },
            {
                label: 'Dispute' , url: '', subMenuChildren:[
                    { label: ' Dispute Status ', url: '', subMenuChildren: [] },
                    { label: ' Dispute Details ', url: '', subMenuChildren: [] },
                ]
            },
            {
                label: 'Miscellaneous' , url: '', subMenuChildren:[
                    { label: ' Performance Threshold Summary ', url: '', subMenuChildren: [] },
                    { label: ' Evaluator Performance ', url: '', subMenuChildren: [] },
                    { label: ' Team Performance ', url: '', subMenuChildren: [] },
                    { label: ' Training Call Report ', url: '', subMenuChildren: [] },
                    { label: ' Calibration Details ', url: '', subMenuChildren: [] },
                ]
            },
            
        ] 
    },

    { label: 'Scheduled Report', url: '/scheduled_report', icon: 'las la-calendar-check', subMenu: [] },
    { label: 'Favorite', url: '/favourite', icon: 'las la-bookmark', subMenu: [] },
    { label: 'DFR', url: '/dfr', icon: 'las la-file-download', subMenu: [] },
    { label: 'Alert', url: '/alert', icon: 'las la-bell', subMenu: [] },
    { label: 'Hierarchy', url: '/hierarchy', icon: 'las la-sitemap', subMenu: [] },
    {
        label: 'Team', url: '', icon: 'las la-users', subMenu: [
            { label: 'Add User', url: '/add-user', subMenuChildren: [] },
            { label: 'Add Agent', url: '/add-agent', subMenuChildren: [] },
        ]
    },
    { label: 'Roles', url: '/roles', icon: 'las la-user-tag', subMenu: [] },
    { label: 'Roles Permissions', url: '/roles-permissions', icon: 'las la-clipboard-check', subMenu: [] },
    // { label: 'Feedback', url: '/feedback', icon: 'las la-comment', subMenu: [] },
    { label: 'Tickets', url: '/tickets', icon: 'las la-question-circle', subMenu: [] },
    { label: 'User Permissions', url: '/users-permissions', icon: 'las la-clipboard-check', subMenu: [] },


    { label: 'Activity Report', url: '/activity_report', icon: 'las la-question-circle', subMenu: [] },
   
    { label: 'QA Evaluator', url: '/qa-evaluator', icon: 'lar la-save', subMenu: [] },
    // smart speech dropdown value for quality side
    {
        label: 'Call-Distribution Analysis', url: '/smartspeech-dashboard', icon: 'las la-microphone', subMenu: [],
    },
    
    {
        label: 'User Login Report', url: '/userHistory', icon: 'las la-users', subMenu: []
    },
    
      

    // LMS sidebar
    {
        label: "LMS",
        url: "",
        icon: "las la-headset",
        subMenu: [
            // {
            //   label: "Create Categories",
            //   url: "/create-categories",
            //   subMenuChildren: [],
            // },
            //   {
            //     label: "Categories",
            //     url: "/categories-list",
            //     subMenuChildren: [],
            //   },
            // {
            //   label: "Create Training",
            //   url: "/create-training",
            //   subMenuChildren: [],
            // },
            {
                label: "Trainings",
                url: "/trainings",
                subMenuChildren: [],
            },
            // { label: "Create Lessons", url: "/add-lessons", subMenuChildren: [] },
            //   { label: "Lessons", url: "/lessons-list", subMenuChildren: [] },
            //   {
            //     label: "Create Questions",
            //     url: "/create-questions",
            //     subMenuChildren: [],
            //   },
            // { label: "Create Test", url: "/add-test", subMenuChildren: [] },
            //   { label: "Tests", url: "/test-list", subMenuChildren: [] },
        ],
    },
    // LMS sidebar
    {
        label: "LMSS",
        url: "",
        icon: "las la-headset",
        subMenu: [
            {
                label: "Trainings",
                url: "/trainings",
                subMenuChildren: [],
            },
        ],
    },

    // Kpi dashboard Evaluation

    // Kpi dashboard Dispute
    // { label: 'Escalation Detail', url: '/dispute/escalation-details' },
    // { label: 'Escalation Status-Closed', url: '/dispute/escalation-status-closed' },
    // { label: 'Escalation Status-Decline', url: '/dispute/escalation-status-declined' },
    // { label: 'Escalation Status-Expired', url: '/dispute/escalation-status-expired' },
    // { label: 'Escalation Status-Open', url: '/dispute/escalation-status-open' },
    // { label: 'Invalid Escalation', url: '/dispute/invalid-escalation' },
    // { label: 'Valid Escalation', url: '/dispute/valid-escalation' },
    // kpi dashboard Dispute

    // Kpi Analysis
    // { label: 'Analysis', url: '/kpis/analysis' },
    // Kpi Analysis

    // Kpi Performance charts
    // { label: 'Audit Performance', url: '/performance_charts/audit_performance' },
    // { label: 'Dispute Performance', url: '/performance_charts/dispute_performance' },
    // { label: 'Evaluation Performance', url: '/performance_charts/evaluation_performance' },
    // { label: 'Evaluation Status', url: '/performance_charts/evaluation_status' },
    // { label: 'LOB Performance', url: '/performance_charts/lob_performance' },
    // { label: 'Vendor Performance', url: '/performance_charts/vendor_performance' },
    // { label: 'Location Performance', url: '/performance_charts/location_performance' },
    // { label: 'Campaign Performance', url: '/performance_charts/campaign_performance' },
    // Kpi Performance charts
]