import React, { useState, useEffect } from "react";

import moment from "moment";
import DataTableView from "../../../shared/DataTable";

import { WordCloudController, WordElement } from "chartjs-chart-wordcloud";
import { ErrorNotify } from "../../Toast";
import StackedHorizontalBar from "./StackedHorizontalBar";

var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
// import { numbers, CHART_COLORS, transparentize, luxon } from "../../shared/Util";

const RecruiterCandidateTable = ({
  fromDate,
  toDate,
  dateRange,
  latestForm,
  formValVersion,
  fromDateForDash,
  toDateForDash,
  dateTypeForDash,
  filterStatus
}) => {
  const [recruCandDetails, setRecruCandDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const columns = React.useMemo(
    () => [
      {
        Header: "Recruiter",
        accessor: "Agent_name",
      },
      {
        Header: "Candidate Satisfaction",
        accessor: "candidateSatisfaction",
      },

      {
        Header: "Candidate's Questions Resolved",
        accessor: "candidateQuestionsResolved",
      },
      {
        Header: "Candidate Understandability ",
        accessor: "candidateUnderstandability",
      },
      {
        Header: "Overall Candidate Sentiment",
        accessor: "overallCandidateSentiment",
      },
      {
        Header: "Predicted Candidate Confidence Level",
        accessor: "predictedCandidateConfidenceLevel",
      },
    ],
    [recruCandDetails]
  );

  //   const evalData = React.useMemo(() =>
  //     evalDetails
  //       ?.filter((item) => {
  //         return searchValue?.toLowerCase() === ""
  //           ? item && item
  //           : (item.call_id &&
  //               item.call_id.toLowerCase().includes(searchValue)) ||
  //               (item.datetime &&
  //                 item.datetime.toString().toLowerCase().includes(searchValue)) ||
  //               (item.campaign &&
  //                 item.campaign.toString().toLowerCase().includes(searchValue)) ||
  //               (item.lob &&
  //                 item.lob.toString().toLowerCase().includes(searchValue)) ||
  //               (item.vendor &&
  //                 item.vendor
  //                   .toString()
  //                   .toString()
  //                   .toLowerCase()
  //                   .includes(searchValue)) ||
  //               (item.location &&
  //                 item.location.toString().toLowerCase().includes(searchValue)) ||
  //               (item.agent_name &&
  //                 item.agent_name
  //                   .toString()
  //                   .toLowerCase()
  //                   .includes(searchValue)) ||
  //               (item.candidateSatisfaction &&
  //                 item.candidateSatisfaction
  //                   .toString()
  //                   .toLowerCase()
  //                   .includes(searchValue)) ||
  //               (item.protocalDeviation &&
  //                 item.protocalDeviation
  //                   .toString()
  //                   .toLowerCase()
  //                   .includes(searchValue));
  //       })
  //       .map((i, j) => ({
  //         call_id: [
  //           <p>
  //             <Link
  //               to={`/new-form-preview?formName=${i.form_name}&formVersion=${
  //                 i.form_version
  //               }&moduleName=evaluation&fromList=univFormCallList&call_id=${
  //                 i?.call_id !== undefined && i?.call_id
  //               }&authSecondId&resolvedSecId`}
  //               target="_blank"
  //               className="td-link cursor-pointer"
  //             >
  //               {i?.call_id !== undefined && i?.call_id}
  //             </Link>
  //           </p>,
  //         ],
  //         totalScore: [
  //           <p className=" text-nowrap">
  //             {i.totalScore !== undefined && i.totalScore}%
  //           </p>,
  //         ],

  //         datetime: [
  //           <p className=" text-nowrap">
  //             {i.datetime !== undefined ? i.datetime : "-"}
  //           </p>,
  //         ],
  //         campaign: [
  //           <p className=" text-nowrap">
  //             {i.campaign !== undefined ? i.campaign : "-"}
  //           </p>,
  //         ],
  //         lob: [
  //           <p className=" text-nowrap">{i.lob !== undefined ? i.lob : "-"}</p>,
  //         ],
  //         vendor: [
  //           <p className=" text-nowrap">
  //             {i.vendor !== undefined ? i.vendor : "-"}
  //           </p>,
  //         ],
  //         location: [
  //           <p className=" text-nowrap">
  //             {i.location !== undefined ? i.location : "-"}
  //           </p>,
  //         ],
  //         agent_name: [
  //           <p className=" text-nowrap">
  //             {i.agent_name !== undefined ? i.agent_name : "-"}
  //           </p>,
  //         ],
  //         candidateSatisfaction: [
  //           <p className=" text-nowrap">
  //             {i.candidateSatisfaction !== undefined
  //               ? i.candidateSatisfaction
  //               : "-"}
  //           </p>,
  //         ],
  //         protocolDeviation: [
  //           <p className=" text-nowrap">
  //             {i.protocolDeviation !== undefined ? i.protocolDeviation : "-"}
  //           </p>,
  //         ],
  //       }))
  //   );
  useEffect(() => {
    if(latestForm?.label!==undefined&&latestForm?.value?.split("||")[0]!==undefined){
    if (
      typeof formValVersion !== "string" &&
      formValVersion?.form_name &&
      formValVersion?.form_version
    ) {
      var url = `${base_url}dashboard-ai/recruiter-candidate-overall-criteria`;
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          start_date: fromDateForDash,
          end_date: toDateForDash,
          daterange: dateRange,
          date_type: dateTypeForDash,
          form:
            process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "recruitment"
              ?  [latestForm?.value]
              : [
                  `${formValVersion?.form_name}||${formValVersion?.form_version}`,
                ],
          proxy_user_id:
            localStorage.getItem("settedProxy") !== null &&
            localStorage.getItem("settedProxy") !== undefined
              ? JSON.parse(localStorage.getItem("settedProxy"))?.value
              : "",
          proxy_by:
            localStorage.getItem("settedProxy") !== null &&
            localStorage.getItem("settedProxy") !== undefined
              ? localStorage.getItem("loginUserUniqueid")
              : "",
        }),
      };
      setIsLoading(true);
      callApi(url, requestOptions, "recruit-table");
    }
  }
  }, [dateRange, formValVersion,filterStatus]);

  const callApi = async (url, requestOptions, flag) => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "recruit-table":
            console.log("Recruite Table", data.data);
            if (data?.status === 200) {
              setRecruCandDetails(data?.data);
              setIsLoading(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status === 401) {
                localStorage.clear();
              }
              setIsLoading(false);
              setErrorMsg(true);
              return ErrorNotify(data.message, {
                icon: "error",
              });
            }
            break;

          default:
            break;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        return ErrorNotify(
          "Something went wrong.Please contact to Administrator !",
          {
            icon: "error",
          }
        );
      });
  };

  const recruiterData = React.useMemo(() =>
    recruCandDetails
      ?.filter((item) => {
        return searchValue?.toLowerCase() === ""
          ? item && item
          : (item.Agent_name &&
              item.Agent_name.toLowerCase().includes(searchValue)) ||
              (item.predictedCandidateConfidenceLevel &&
                item.predictedCandidateConfidenceLevel
                  .toString()
                  .toLowerCase()
                  .includes(searchValue));
      })
      .map((i, j) => ({
        Agent_name: [
          <p className=" text-nowrap">
            {i.Agent_name !== undefined ? i.Agent_name : "-"}
          </p>,
        ],
        candidateSatisfaction:<><StackedHorizontalBar isStatic={false} barData={i?.candidateSatisfaction ? i?.candidateSatisfaction : []}/></>,
        candidateQuestionsResolved:<><StackedHorizontalBar isStatic={false} barData={i?.candidateQuestionsResolved ? i?.candidateQuestionsResolved : []}/></>,
        candidateUnderstandability:<><StackedHorizontalBar isStatic={false} barData={i?.candidateUnderstandability ? i?.candidateUnderstandability : []}/></>,
        overallCandidateSentiment:<><StackedHorizontalBar isStatic={false} barData={i?.overallCandidateSentiment ? i?.overallCandidateSentiment : []}/></>,

        
        predictedCandidateConfidenceLevel: <><StackedHorizontalBar isStatic={true} markerValue={i.predictedCandidateConfidenceLevel}/></>,
        
      }))
  );

  return (
    
    <DataTableView
      columns={columns}
      title="Recruiter Candidate Overall Criteria"
      //   allData={recruCandDetails}
      data={recruiterData}
      totalRec={recruCandDetails?.length}
      isCSVReport={true}
      isPDFReport={false}
      isXLSReport={false}
      csvReport={csvReport}
      pdfReport={pdfReport}
      xlsReport={xlsReport}
      setSearchValue={setSearchValue}
      isPending={isLoading}
      pagination
    />
  );
};

export default RecruiterCandidateTable;
