import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { ErrorNotify } from "../../Toast";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const CardData2 = ({
  dateRange,
  fromDate,
  toDate,
  setIsLoading2,
  daterangetype,
  setCardData,
  cardData,
  formValVersion,
  color,
  fromDateForDash,
  toDateForDash,
  dateTypeForDash,
  latestForm,
  setEvalDetails,
}) => {
  const navigate = useNavigate();

  //const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    console.log("kjjkjkjkjk", latestForm);
    if(latestForm?.label!==undefined&&latestForm?.value?.split("||")[0]!==undefined){
    if (
      typeof formValVersion !== "string" &&
      formValVersion?.form_name &&
      formValVersion?.form_version
    ) {
      var url = `${base_url}dashboard-ai/card-data`;
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          start_date: fromDateForDash,
          end_date: toDateForDash,
          daterange: dateRange,
          date_type: dateTypeForDash,
          form:
            process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "recruitment"
              ? [latestForm?.value]
              : [
                  `${formValVersion?.form_name}||${formValVersion?.form_version}`,
                ],
          proxy_user_id:
            localStorage.getItem("settedProxy") !== null &&
            localStorage.getItem("settedProxy") !== undefined
              ? JSON.parse(localStorage.getItem("settedProxy"))?.value
              : "",
          proxy_by:
            localStorage.getItem("settedProxy") !== null &&
            localStorage.getItem("settedProxy") !== undefined
              ? localStorage.getItem("loginUserUniqueid")
              : "",
        }),
      };
      setIsLoading(true);
      callApi(url, requestOptions, "card-data");
    }
  }
  }, [formValVersion]);

  const callApi = async (url, requestOptions, flag) => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "card-data":
            // console.log('card data is here=========', data)

            if (data.status === 200) {
              setIsLoading(false);
              setCardData(data.data);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status === 401) {
                localStorage.clear();
              }
              setIsLoading(false);
              return ErrorNotify(data.message, {
                icon: "error",
              });
            }

            break;

          default:
            break;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        return ErrorNotify(
          "Something went wrong.Please contact to Administrator !",
          {
            icon: "error",
          }
        );
      });
  };

  const getEvaluationTableData = () => {
    setIsLoading2(true);
    var url = `${base_url}dashboard-ai/evaluations-listing`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        start_date: fromDateForDash,
        end_date: toDateForDash,
        daterange: dateRange,
        date_type: dateTypeForDash,
        form:
          process.env.REACT_APP_AUTH_BASE_URL?.split("/")[3] === "recruitment"
            ? [latestForm?.value?.split("||")[0]===undefined?"initial_screening||1":latestForm?.value]
            : [`${formValVersion?.form_name}||${formValVersion?.form_version}`],
        proxy_user_id:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? JSON.parse(localStorage.getItem("settedProxy"))?.value
            : "",
        proxy_by:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? localStorage.getItem("loginUserUniqueid")
            : "",
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("eval_Details");
        if (data?.status == 200) {
          setIsLoading2(false);
          setEvalDetails(data?.data);
        } else if (data?.status >= 400 && data?.status <= 422) {
          if (data?.status === 401) {
            localStorage.clear();
          }
          return ErrorNotify(data.message, { icon: "error" });
        }
      })
      .catch((err) => {
        setIsLoading2(false);
        return ErrorNotify(
          "Something went wrong.Please contact to Administrator !",
          {
            icon: "error",
          }
        );
      });
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  return (
    <>
      <div className="ip-section mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div
                className={`status-card ${
                  !color && "ip-gradient"
                } d-flex justify-content-between align-items-center`}
                style={{ backgroundColor: color && color[0] }}
              >
                <h3 className="status-heading">
                  <span style={{ fontSize: "15px" }}>
                    {window.location.hostname == "neofinancial.neqqo.com"
                      ? "Manual QA Score"
                      : "Auto QA Score"}
                  </span>
                </h3>

                {isLoading ? (
                  <span
                    className={`status-count ${isLoading ? "loading" : ""}`}
                    id="evaluationid"
                    style={{ fontSize: "20px" }}
                  >
                    {isLoading && (
                      <span class="loader">
                        <span
                          class="spinner-border"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </span>
                    )}
                  </span>
                ) : (
                  <span className="status-count " style={{ fontSize: "20px" }}>
                    {cardData.overall_qa_score
                    ? cardData.overall_qa_score : 0}
                  </span>
                )}
                {/* <span
                  style={{ fontSize: "20px" }}
                  className={`status-count ${isLoading ? "loading" : ""}`}
                  id="passrateid"
                >
                  {cardData.overall_qa_score
                    ? cardData.overall_qa_score
                    : !isLoading && 0}
                  {isLoading && (
                    <span class="loader">
                      <span
                        class="spinner-border"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </span>
                  )}
                </span> */}
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3">
              <div
                className={`status-card ${
                  !color && "ip-gradient"
                } d-flex justify-content-between align-items-center`}
                style={{ backgroundColor: color && color[1] }}
              >
                <h3 className="status-heading">
                  <span style={{ fontSize: "15px" }}>Evaluations</span>
                </h3>

                {isLoading ? (
                  <span
                    className={`status-count ${isLoading ? "loading" : ""}`}
                    id="evaluationid"
                    style={{ fontSize: "20px" }}
                  >
                    {isLoading && (
                      <span class="loader">
                        <span
                          class="spinner-border"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </span>
                    )}
                  </span>
                ) : (
                  <span className="status-count" onClick={getEvaluationTableData} style={{ fontSize: "20px",textDecoration: "underline",cursor: "pointer" }}>
                    {cardData.total_evaluations
                      ? cardData.total_evaluations
                      : 0}
                  </span>
                )}
                {/* <span
                  className={`status-count ${isLoading ? "loading" : ""}`}
                  onClick={getEvaluationTableData}
                  id="evaluationid"
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {cardData.total_evaluations
                    ? cardData.total_evaluations
                    : !isLoading && 0}
                  {isLoading && (
                    <span class="loader">
                      <span
                        class="spinner-border"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </span>
                  )}
                </span> */}
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3">
              <div
                className={`status-card ${
                  !color && "ip-gradient"
                } d-flex justify-content-between align-items-center`}
                style={{ backgroundColor: color && color[2] }}
              >
                <h3 className="status-heading">
                  <span style={{ fontSize: "15px" }}>CSAT</span>
                </h3>
                {isLoading ? (
                  <span
                    className={`status-count ${isLoading ? "loading" : ""}`}
                    id="evaluationid"
                    style={{ fontSize: "20px" }}
                  >
                    {isLoading && (
                      <span class="loader">
                        <span
                          class="spinner-border"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </span>
                    )}
                  </span>
                ) : (
                  <span className="status-count " style={{ fontSize: "20px" }}>
                    {cardData.csat ? cardData.csat : 0}
                  </span>
                )}
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-2.5">
              <div
                className={`status-card ${
                  !color && "ip-gradient"
                } d-flex justify-content-between align-items-center`}
                style={{ backgroundColor: color && color[3] }}
              >
                <h3 className="status-heading">
                  <span style={{ fontSize: "15px" }}>Compliance</span>
                </h3>

                {isLoading ? (
                  <span
                    className={`status-count ${isLoading ? "loading" : ""}`}
                    id="evaluationid"
                    style={{ fontSize: "20px" }}
                  >
                    {isLoading && (
                      <span class="loader">
                        <span
                          class="spinner-border"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </span>
                    )}
                  </span>
                ) : (
                  <span className="status-count " style={{ fontSize: "20px" }}>
                    {cardData.compliance ? cardData.compliance : 0}
                  </span>
                )}
                {/* <span className={`status-count ${isLoading ? 'loading' : ''}`} id="evaluationid" style={{ fontSize: '20px' }}>
                                    {cardData.compliance ? cardData.compliance : !isLoading&&0}
                                     {isLoading && <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>}
                                 </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardData2;
